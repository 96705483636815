import React, {FC, ReactNode} from 'react'
import {Container, Row} from 'react-bootstrap-v5'
import PageTitle from '_metronic/layout/components/header/page-title/PageTitle'
import './style.scss'

interface Props {
  rightChildren?: ReactNode
}

const Toolbar: FC<Props> = ({rightChildren}) => {
  return (
    <div className='toolbar toolbar blur-darkBlue' id='kt_toolbar'>
      <Container fluid>
        <Row className='align-items-center justify-content-between'>
          <PageTitle showNavigation={false} />
          {rightChildren}
        </Row>
      </Container>
    </div>
  )
}

export default Toolbar
