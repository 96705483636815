import {fromJS} from 'immutable'
import MAP_STYLE from '../consts/mapStyle.json'

const defaultMapStyle: any = fromJS(MAP_STYLE)
const defaultLayers: any = defaultMapStyle.get('layers')

const categories = ['labels', 'roads', 'parks', 'water', 'background']

const layerSelector = {
  background: /background/,
  water: /water/,
  parks: /park/,
  roads: /bridge|road|tunnel/,
  labels: /label|place|poi/,
}

const colorClass = {
  line: 'line-color',
  fill: 'fill-color',
  background: 'background-color',
  symbol: 'text-color',
}

/**
 * Returns the modified map styles
 * @return {any} Map styles
 */
export function getMapStyle(): any {
  const visibility: any = {
    water: true,
    roads: true,
    parks: false,
    labels: true,
    background: true,
  }

  const color: any = {
    water: '#282a3d',
    roads: '#6d6d80',
    labels: '#cdcdde',
    background: '#1e1e2d',
  }

  const layers = defaultLayers
    .filter((layer: any) => {
      const id = layer.get('id')

      // @ts-ignore
      return categories.every((name) => visibility[name] || !layerSelector[name].test(id))
    })
    .map((layer: any) => {
      const id = layer.get('id')
      const type = layer.get('type')
      // @ts-ignore
      const category = categories.find((name) => layerSelector[name].test(id))
      // @ts-ignore
      if (category && colorClass[type]) {
        // @ts-ignore
        return layer.setIn(['paint', colorClass[type]], color[category])
      }
      return layer
    })

  return defaultMapStyle.set('layers', layers)
}
