import React, {FC, Fragment, useState} from 'react'
import {KTSVG} from '_metronic/helpers'
import {ValidationLevel} from 'consts'
import {RequestOverview} from './components'
import {useMediaQuery} from 'react-responsive'
import './style.scss'

interface Props {
  validationObject: ValidationObjectUnion
  showOnlyErrors: boolean
}

const ContentRow: FC<Props> = ({validationObject, showOnlyErrors}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const isSmallScreen = useMediaQuery({query: '(max-width: 576px)'})

  let icon

  if (showOnlyErrors && validationObject.status >= ValidationLevel.SUCCESS) {
    return <Fragment />
  }

  switch (validationObject.status) {
    case ValidationLevel.SUCCESSALL:
      icon = <KTSVG path={'/media/icons/duotune/general/gen043.svg'} className='svg-icon-success svg-icon-2x' />
      break
    case ValidationLevel.SUCCESS:
      icon = <KTSVG path={'/media/icons/duotune/general/gen043.svg'} className='svg-icon-success svg-icon-2x' />
      break
    case ValidationLevel.INFO:
      icon = <KTSVG path={'/media/icons/duotune/general/gen045.svg'} className='svg-icon-primary svg-icon-2x' />
      break
    case ValidationLevel.WARN:
      icon = <KTSVG path={'/media/icons/duotune/general/gen044.svg'} className='svg-icon-warning svg-icon-2x' />
      break
    default:
      icon = <KTSVG path={'/media/icons/duotune/general/gen040.svg'} className='svg-icon-danger svg-icon-2x' />
  }
  const toggle = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <>
      <tr className='ContentRow'>
        <td className='statusIcon col-auto svg-icon-warning'>{icon}</td>
        <td className='col-2 contentRowHeader'>
          <p className='mb-0 mt-2 text-gray-700 text-break'>{validationObject.header}</p>
        </td>
        <td>
          <h3 className='mb-0 mt-2 text-break'>{validationObject.message}</h3>
          <p className='mb-0 text-break text-gray-700'>{validationObject.details}</p>
          {validationObject.__typename === 'RequestObject' &&
            (!isSmallScreen ? (
              <RequestOverview validationObject={validationObject} />
            ) : (
              <div className='d-flex align-items-center cursor-pointer mt-4' onClick={toggle}>
                {isExpanded ? (
                  <KTSVG path={'/media/icons/duotune/arrows/arr073.svg'} className='svg-icon-gray svg-icon-2x' />
                ) : (
                  <KTSVG path={'/media/icons/duotune/arrows/arr072.svg'} className='svg-icon-gray svg-icon-2x' />
                )}
                <p className='text-gray-700 mb-0' style={{marginLeft: 5}}>
                  Show {isExpanded ? 'less' : 'more'}
                </p>
              </div>
            ))}
        </td>
      </tr>
      {validationObject.__typename === 'RequestObject' && isSmallScreen && isExpanded && (
        <tr className='ContentRow MobileContentRow'>
          <td colSpan={3} className='mobileContent'>
            <RequestOverview validationObject={validationObject} />
          </td>
        </tr>
      )}
    </>
  )
}

export default ContentRow
