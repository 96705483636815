import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '_metronic/helpers'
import './style.scss'

interface Props {
  info: HoverTooltip
}

const Tooltip: FC<Props> = ({info}) => {
  const {object, x, y, viewport} = info
  const mapWidth = viewport?.width || 0
  const [indent, setIndent] = useState(0)

  useEffect(() => {
    const mapTooltipWidth = (document.getElementById('mapTooltip')?.clientWidth || 0) + 5
    if (mapWidth > 0 && mapTooltipWidth + x >= mapWidth) {
      setIndent(mapTooltipWidth)
    } else {
      setIndent(0)
    }
  }, [info])

  if (('objects' in info && info.objects) || (object && 'url' in object && info.full)) {
    let list = info.objects ? info.objects : object && 'url' in object && [object]
    if (list) list = list.sort((i) => (i.isWorking ? -1 : 1))
    const isMobile = window.innerWidth <= 440
    const large = isMobile && (mapWidth * 3) / 5 <= (document.getElementById('mapTooltip')?.clientWidth || 0) + 5

    return list ? (
      <div style={{left: large ? 30 : x - indent, top: y}} className='mapTooltip interactive' id='mapTooltip'>
        {list.map(({url, isWorking}, num) => {
          return (
            <div key={url + num} className='d-flex align-items-center'>
              <p className='numbering mb-0 text-muted'>{num + 1}</p>
              {isWorking ? (
                <KTSVG path={'/media/icons/duotune/general/gen043.svg'} className='svg-icon-success svg-icon-x' />
              ) : (
                <KTSVG path={'/media/icons/duotune/general/gen040.svg'} className='svg-icon-danger svg-icon-x' />
              )}
              <p className='mb-0'>{url}</p>
            </div>
          )
        })}
      </div>
    ) : null
  }

  if (!object) {
    return null
  }

  return 'cluster' in object && object ? (
    <div style={{left: x - indent, top: y + 5}} className='mapTooltip' id='mapTooltip'>
      {object.point_count} records
    </div>
  ) : (
    object && (
      <div style={{left: x - indent, top: y + 5}} className='mapTooltip' id='mapTooltip'>
        1 record
      </div>
    )
  )
}

export default Tooltip
