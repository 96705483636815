import cn from 'classnames'
import React, {FC, useEffect} from 'react'
import {Nav} from 'react-bootstrap-v5'
import {useHistory, useLocation} from 'react-router-dom'
import ScrollSpy from 'react-scrollspy-navigation'
import {KTSVG} from '_metronic/helpers'
import {ValidationLevel, ContentType} from 'consts'
import Skeleton from 'react-loading-skeleton'
import './style.scss'

interface Props {
  data: SideNavItem[]
  contentType: ContentType
  isMobile?: boolean
}

const SideScrollspyNav: FC<Props> = ({data, contentType, isMobile}) => {
  const history = useHistory()
  const location = useLocation()

  const handleClick = (hash: string) => {
    history.replace({search: location.search, hash})
  }

  useEffect(() => {
    if (data.length && location.hash) {
      setTimeout(() => {
        const y = document.getElementById(location.hash.replace('#', ''))?.offsetTop
        if (y) {
          window.scrollTo({top: y - 140, behavior: 'smooth'})
        }
      }, 500)
    }
  }, [data])

  return (
    <>
      {data.length ? (
        <div className={cn('sideScrollspyNav card py-4 px-3', {mobile: isMobile})}>
          <Nav variant='pills' defaultActiveKey='org' className='flex-column'>
            <ScrollSpy offsetTop={150}>
              {data.map((i, num) =>
                <a
                  onClick={() => handleClick('#' + (i.id ? `i${i.id}` : i.label.toLowerCase()))}
                  className={cn({'mt-4': num !== 0})}
                  href={'#' + (i.id ? `i${i.id}` : i.label.toLowerCase())}
                  ref={React.createRef()}
                  key={i.label + '-' + num}
                >
                  <Nav.Item>
                    <Nav.Link className='px-5 py-2 element'>
                      <div className='d-flex align-items-center'>
                        {contentType === ContentType.Validation && i.status && getStatusIcon(i.status)}
                        {i.icon && <KTSVG path={'/media/icons/duotune' + i.icon} className='svg-icon-gray-700' />}
                        <h6 className='mb-0 ml-1 fs-7 text-gray-800 d-flex align-items-center'>
                          {i.label} {i.url && 'Node'}
                          {i.isSsl && <span className='badge badge-secondary ml-1 fs-9'>SSL</span>}
                        </h6>
                      </div>
                      {i.url &&
                      <p className='text-gray-700 fs-9 mb-0 mt-1'>{i.url}</p>
                      }
                    </Nav.Link>
                  </Nav.Item>
                </a>
              )}
            </ScrollSpy>
          </Nav>
        </div>
      ) : (
        <Skeleton height={500} width={230} count={1} className='react-loading-skeleton-guild-validation-table' />
      )}
    </>
  )
}

export default SideScrollspyNav

/**
 * The function returns an icon representing the ValidationLevel (e.g. warning sign)
 * @param {enum} validationLevel
 * @return {JSX.Element} Validation level icon
 */
function getStatusIcon(validationLevel: ValidationLevel): JSX.Element {
  switch (validationLevel) {
    case ValidationLevel.SUCCESSALL:
      return <KTSVG path={'/media/icons/duotune/general/gen043.svg'} className='svg-icon-gray-700' />
    case ValidationLevel.SUCCESS:
      return <KTSVG path={'/media/icons/duotune/general/gen043.svg'} className='svg-icon-gray-700' />
    case ValidationLevel.INFO:
      return <KTSVG path={'/media/icons/duotune/general/gen045.svg'} className='svg-icon-gray-700' />
    case ValidationLevel.WARN:
      return <KTSVG path={'/media/icons/duotune/general/gen044.svg'} className='svg-icon-gray-700' />
    default:
      return <KTSVG path={'/media/icons/duotune/general/gen040.svg'} className='svg-icon-gray-700' />
  }
}
