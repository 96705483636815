import {NodeType} from 'consts'

/**
 * Returns a string (enum NodeType) if the query passes validation (e.g. ?type=api, type=atomic) or null if validation failed
 * @param {string} query (e.g. ?type=seed)
 * @return {string | null }
 */
export function getNodeType(query: string): string | null {
  const queryParams = new URLSearchParams(query)
  const nodeTypeParams = queryParams.get('type')
  const currentNodeType =
    nodeTypeParams && Object.keys(NodeType).find((i) => i === 'NODE_' + nodeTypeParams.toUpperCase())
  if (currentNodeType) {
    return currentNodeType.toUpperCase()
  }
  return null
}
