import React, {FC} from 'react'
import {AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts'
import moment from 'moment'

interface Props {
  data: AvailabilityValue[]
}

const ChartArea: FC<Props> = ({data}) => {
  return (
    <ResponsiveContainer width='100%' minWidth={100} height={350} debounce={50} className='chart-area'>
      <AreaChart
        width={100}
        height={350}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id='areaChartGradient' x1='0' y1='0' x2='0' y2='1' rotate={45}>
            <stop offset='5%' stopColor={'#7d54c7'} stopOpacity={.85}/>
            <stop offset='95%' stopColor={'#633bad'} stopOpacity={.85}/>
          </linearGradient>
        </defs>
        <XAxis
          dataKey={i => moment(i.date).unix()}
          domain={['dataMin', 'dataMax']}
          type='number'
          tickCount={9}
          tickFormatter = {i => moment.unix(i).format('DD. MMM')}
        />
        <YAxis unit='%' domain={[0, 100]} tickLine={false} axisLine={false} />
        <Tooltip
          itemStyle={{color: '#9a7ad3'}}
          formatter={formatTooltip}
          labelFormatter={formatTooltipLabel}
          separator={''}
          contentStyle={
            {
              borderRadius: 5,
              border: 'none',
              color: '#fff',
              backgroundColor: '#323248'
            }
          }
        />
        <Area
          type='monotone'
          dataKey='availability'
          stroke={'#4d2e87'}
          fill='url(#areaChartGradient)'
          fillOpacity={1}
        />
        <CartesianGrid vertical={false} stroke='#92929f' opacity={.2}/>
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default ChartArea


// eslint-disable-next-line require-jsdoc
function formatTooltip(value: number) {
  return [value + '%', 'Availability: ']
}

// eslint-disable-next-line require-jsdoc
function formatTooltipLabel(label: number) {
  return moment.unix(label).format('DD. MMM YYYY')
}
