import React, {FC, Fragment} from 'react'
import {EndpointRow} from './components'
import './style.scss'

interface Props {
  className: string
  nodeType: string
  endpoints: Endpoint[]
  guilds: Guild[]
  loading: boolean
  error: boolean
}

const EndpointTable: FC<Props> = (
  {
    className,
    nodeType,
    endpoints,
    guilds,
    loading,
    error
  }
) => {
  const rowsWorking: JSX.Element[] = []
  const rowsNotWorking: JSX.Element[] = []
  const rowsLoading: JSX.Element[] = []

  if (loading) {
    for (let i = 0; i < 15; i++) {
      rowsLoading.push(
        <EndpointRow key={nodeType + i} nodeType={nodeType} node={undefined} guilds={undefined} loading={true} />
      )
    }
  } else if (!error) {
    endpoints.forEach((node, num) => {
      if (node.all_checks_ok === 4 || (nodeType.toLowerCase() == 'hyperion' && node?.partial_checks_ok === 4)) {
        rowsWorking.push(
          <EndpointRow
            key={node.endpoint_url + num}
            node={node}
            guilds={guilds}
            nodeType={nodeType.toLowerCase()}
            loading={false}
          />
        )
      } else {
        rowsNotWorking.push(
          <EndpointRow
            key={node.endpoint_url + num}
            node={node}
            guilds={guilds}
            nodeType={nodeType.toLowerCase()}
            loading={false}
          />
        )
      }
    })
  }

  return (
    <div className={`card ${className} endpointTable`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Endpoint List</span>
        </h3>
        {!loading && (
          <h3 className='card-title align-items-end flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              {rowsWorking.length} / {rowsWorking.length + rowsNotWorking.length}
            </span>
            <span className='text-muted mt-1 fw-bolder fs-7'>working endpoints</span>
          </h3>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-hover table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr>
                    <th className='w-30px' />
                    <th className='p-0 w-50px' />
                    {nodeType.toLowerCase() !== 'seed' && <th className='text-muted w-70px'>SSL</th>}
                    {nodeType.toLowerCase() == 'hyperion' && <th className='text-muted w-70px'>History</th>}
                    <th className='text-muted min-w-120px'>Endpoint</th>
                    <th className='text-muted'>Guild</th>
                    {(nodeType.toLowerCase() === 'api' ||
                      nodeType.toLowerCase() === 'hyperion' ||
                      nodeType.toLowerCase() === 'atomic') && <th className='text-muted'>Server Version</th>}
                    <th className='text-muted' />
                  </tr>
                  <div className='borderLine' />
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {loading ? (
                    rowsLoading
                  ) : (
                    <Fragment>
                      {rowsWorking}
                      {rowsNotWorking}
                    </Fragment>
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export default EndpointTable
