import {gql} from '@apollo/client'

export const STATS_BY_GUILD = gql`
  query ($guild: String!, $date: Date = null) {
    getValidationByGuild(guild: $guild, date: $date) {
      id
      guild
      validation_date
      guild_logo_256_url
      all_checks_ok
      nodes {
        node_type
        nodes {
          id
          node_type
          endpoint_url
          is_ssl
          all_checks_ok
          partial_checks_ok
          location_longitude
          location_latitude
          statistics {
            availability {
              date
              availability
            }
          }
        }
      }
    }
  }
`
