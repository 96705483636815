import cn from 'classnames'
import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '_metronic/helpers'
import Skeleton from 'react-loading-skeleton'
import 'app/pages/Report/components/EndpointTable/components/EndpointRow/style.scss'

interface Props {
  nodeType: string
  node?: Endpoint
  loading: boolean
  guilds: Guild[] | undefined
}

const EndpointRow: FC<Props> = ({nodeType, node, loading, guilds}) => {
  const [guildLogo, setGuildLogo] = useState<null | string>(null)
  const [imageStatus, setImageStatus] = useState('loading')

  useEffect(() => {
    if (!loading && guilds?.length) {
      const logo = guilds.filter((x) => x.name === node?.guild)[0].url_logo_256
      setGuildLogo(logo || null)
    }
  }, [loading, guilds])

  return (
    <>
      <tr className='endpointRow'>
        <td>
          {!loading &&
            (node?.all_checks_ok === 4 || (nodeType.toLowerCase() == 'hyperion' && node?.partial_checks_ok === 4) ? (
              <KTSVG path={'/media/icons/duotune/general/gen043.svg'} className='svg-icon-success svg-icon-2x' />
            ) : (
              <KTSVG path={'/media/icons/duotune/general/gen040.svg'} className='svg-icon-danger svg-icon-2x' />
            ))}
        </td>
        <td>
          {loading ? (
            <Skeleton height={35} width={35} circle={true} />
          ) : (
            <div className='symbol symbol-45px me-2'>
              <span className='symbol-label'>
                {guildLogo && (imageStatus === 'loading' || imageStatus === 'loaded') ?
                  <>
                    <img src={guildLogo} onLoad={() => setImageStatus('loaded')} onError={() => setImageStatus('failed to load')} width='35' height='35' alt='Guild logo' className={cn({'d-none': imageStatus === 'loading'})}/>
                    { imageStatus === 'loading' &&
                    <div className='h-35px w-35px fallbackLogo'/>
                    }
                  </>
                  :
                  <div className='h-35px w-35px fallbackLogo'/>
                }
              </span>
            </div>
          )}
        </td>
        {nodeType.toLowerCase() !== 'seed' && (
          <td className='text'>
            {loading ? (
              <Skeleton height={25} width={45} />
            ) : (
              node?.is_ssl && <span className='badge badge-light-dark'>SSL</span>
            )}
          </td>
        )}
        {nodeType.toLowerCase() == 'hyperion' && (
          <td className='text'>
            {loading ? (
              <Skeleton height={25} width={45} />
            ) : (
              node?.partial_checks_ok == 4 && (node?.all_checks_ok == 4 ? <span className='badge badge-success'>Full</span> : <span className='badge badge-info'>Partial</span>)
            )}
          </td>
        )}
        <td>
          <a
            href={(!loading && node?.endpoint_url) || '/#'}
            className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
            target='_blank'
            rel='noreferrer noopener'
          >
            {loading ? <Skeleton height={25} /> : node?.endpoint_url}
          </a>
        </td>
        <td className='text-muted fw-bold'>{loading ? <Skeleton height={25} /> : node?.guild}</td>
        {(nodeType.toLowerCase() === 'api' ||
          nodeType.toLowerCase() === 'hyperion' ||
          nodeType.toLowerCase() === 'atomic') && (
          <td className='text-muted fw-bold'>{loading ? <Skeleton height={25} /> : node?.server_version}</td>
        )}
        <td className='text-end'>
          {loading ? (
            <Skeleton height={25} width={35} />
          ) : (
            <a
              href={'/validations/' + node?.guild}
              className='btn btn-sm btn-icon btn-bg-secondary btn-active-color-primary'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
            </a>
          )}
        </td>
      </tr>
      <div className='borderLine' />
    </>
  )
}

export default EndpointRow
