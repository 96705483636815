import React, {FC, useEffect, useState} from 'react'
import Skeleton from 'react-loading-skeleton'

import {StaticMap, FlyToInterpolator} from 'react-map-gl'
import DeckGL from '@deck.gl/react'
import {MapView} from '@deck.gl/core'
import {IconLayer, ArcLayer, ScatterplotLayer} from '@deck.gl/layers'
import useThrottle from 'hooks/useThrottle'
import LocationIconAtlas from 'assets/icons/locationIconAtlas.png'
import SecondaryLocationIconAtlas from 'assets/icons/secondaryLocationIconAtlas.png'
import LocationIconMapping from './consts/locationIconMapping.json'
import {IconClusterLayer, Tooltip} from './components'
import {getMapStyle} from 'helpers/getMapStyle'
import CustomMarkerIcon from 'assets/icons/customMarker.png'
import './style.scss'

import mapboxgl from 'mapbox-gl'
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-var-requires
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

interface Props {
  markers: Marker[]
  secondaryMarkers?: Marker[]
  onChangeMarkerCoordinates?: (value: number[]) => void
  loading?: boolean
  markerCoordinates?: number[]
  userLocationCoordinates?: number[]
  radius?: number
  nodeType?: string
  withoutHeader?: boolean
}

const INITIAL_VIEW_STATE = {
  id: 'initial-view-state',
  longitude: -35,
  latitude: 36.7,
  zoom: 1.8,
  maxZoom: 20,
  pitch: 0,
  bearing: 0,
  transitionDuration: 2000,
  transitionInterpolator: new FlyToInterpolator(),
}

const mapStyle = getMapStyle()

const Map: FC<Props> = ({
  markers,
  secondaryMarkers,
  onChangeMarkerCoordinates,
  loading,
  markerCoordinates,
  radius,
  userLocationCoordinates,
  nodeType,
  withoutHeader
}) => {
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE)
  const [mapView, setMapView] = useState(new MapView({repeat: true, controller: true}))
  const [customMarker, setCustomMarker] = useState<any>(null)
  const [hoverInfo, setHoverInfo] = useState<HoverTooltip | null>(null)
  const [userLocation, setUserLocation] = useState<any>(null)
  const [arcLayer, setArcLayer] = useState<any>(null)
  const [layer, setLayer] = useState<any>(null)
  const [secondaryLayer, setSecondaryLayer] = useState<any>(null)
  const [showHint, setShowHint] = useState(true)

  const getLayer = (hoverInfo: HoverTooltip | null, secondary?: boolean) => {
    return new IconClusterLayer({
      data: secondary ? secondaryMarkers : markers,
      pickable: true,
      getPosition: (d: {coordinates: number[]}) => d.coordinates,
      iconAtlas: secondary ? SecondaryLocationIconAtlas : LocationIconAtlas,
      iconMapping: LocationIconMapping,
      onHover: !hoverInfo?.full && !hoverInfo?.objects && setHoverInfo,
      id: secondary ? 'secondary-icon-cluster' : 'icon-cluster',
      sizeScale: 40,
    })
  }

  const hideTooltip = () => {
    radius && changeArcData(customMarker, layer, markerCoordinates, userLocation)
    hoverInfo && setHoverInfo(null)
  }

  const expandTooltip = (info: HoverTooltip) => {
    setHoverInfo(null)
    if (!info.object && radius && !customMarker) {
      onChangeMarkerCoordinates && onChangeMarkerCoordinates(info.coordinate)
      setCustomMarker(getCustomIconLayer(info.coordinate))
    }
    if (info.picked) {
      setHoverInfo({...info, full: true})
    }
  }

  const getCustomIconLayer = (coordinates?: number[]) => {
    if (coordinates) {
      setShowHint(false)
      return new IconLayer({
        getPolygonOffset: () => [-5, -5],
        getPosition: (d: {coordinates: number[]}) => d.coordinates,
        iconAtlas: CustomMarkerIcon,
        iconMapping: {marker: {x: 0, y: 0, width: 30, height: 30, mask: true}},
        data: [{coordinates, info: 'Custom marker'}],
        id: 'customMarker',
        getIcon: () => 'marker',
        sizeUnits: 'meters',
        sizeScale: 15,
        sizeMinPixels: 25,
        radiusPixels: 25,
        pickable: true,
        getColor: () => [221, 0, 0],
        onDragStart: () => {
          setMapView(new MapView({controller: false}))
        },
        onDrag: ({coordinate}: {coordinate: number[]}) => {
          if (onChangeMarkerCoordinates) {
            onChangeMarkerCoordinates(coordinate)
          }
          setCustomMarker(getCustomIconLayer(coordinate))
        },
        onDragEnd: () => {
          setMapView(new MapView({controller: true}))
        },
      }) as any
    }
  }

  const changeArcData = useThrottle((customMarker, layer, markerCoordinates) => {
    if (customMarker && layer) {
      const arcData =
        layer.state &&
        layer.state.data.map(({geometry}: any) => ({
          from: {
            name: 'Custom marker',
            coordinates: markerCoordinates,
          },
          to: {
            name: 'Marker',
            coordinates: geometry.coordinates,
          },
        }))
      setArcLayer(
        new ArcLayer({
          id: 'arc-layer',
          data: arcData,
          pickable: false,
          getWidth: 3,
          getSourcePosition: (d: any) => d.from.coordinates,
          getTargetPosition: (d: any) => d.to.coordinates,
          getSourceColor: () => [205, 205, 222],
          getTargetColor: () => [205, 205, 222],
        })
      )
    }
  }, 100)

  useEffect(() => {
    if (userLocationCoordinates && !!userLocationCoordinates.length) {
      setShowHint(false)
      setViewState((current) => ({
        ...current,
        longitude: userLocationCoordinates[0],
        latitude: userLocationCoordinates[1],
        zoom: 5,
        id: 'modify-view-state-' + Math.floor(Math.random() * 1000),
      }))
      setUserLocation(
        new ScatterplotLayer({
          getPolygonOffset: () => [0, 1],
          id: 'user-location',
          data: [{coordinates: userLocationCoordinates, info: 'User Location'}],
          pickable: false,
          stroked: true,
          filled: true,
          radiusScale: 2,
          radiusMinPixels: 10,
          radiusMaxPixels: 100,
          lineWidthMinPixels: 3,
          getPosition: (d: any) => d.coordinates,
          getFillColor: () => [0, 158, 246],
          getLineColor: () => [255, 255, 255],
        })
      )
      setCustomMarker(getCustomIconLayer(userLocationCoordinates))
    }
    if (!!markerCoordinates?.length && customMarker) {
      setCustomMarker(getCustomIconLayer(markerCoordinates))
    }
  }, [userLocationCoordinates, nodeType, loading])

  useEffect(() => {
    if (radius && customMarker) {
      changeArcData(customMarker, layer, markerCoordinates)
    }
  }, [customMarker, markers, radius, markerCoordinates, layer])

  useEffect(() => {
    if (radius && customMarker) {
      setTimeout(() => {
        changeArcData(customMarker, layer, markerCoordinates)
      }, 100)
    }
  }, [layer])

  useEffect(() => {
    setLayer(getLayer(hoverInfo))
    radius && setSecondaryLayer(getLayer(hoverInfo, true))
  }, [markers, hoverInfo])

  return (
    <>
      {!loading ? (
        <div className='map card position-relative map h-600px'>
          {showHint && radius && (
            <div className='hint text-center'>
              <p className='mb-0'>
                Click anywhere on the map to place the marker.
                <br /> Click and hold the marker to move it
              </p>
            </div>
          )}
          {!withoutHeader && <div className='mapHeader'>
            <h3 className='mb-0 fs-3'>Endpoint Map</h3>
          </div> }
          <DeckGL
            layers={[layer, secondaryLayer, customMarker, userLocation, arcLayer]}
            views={mapView}
            initialViewState={viewState}
            onViewStateChange={hideTooltip}
            onClick={expandTooltip}
          >
            <StaticMap
              reuseMaps
              mapStyle={mapStyle}
              preventStyleDiffing={true}
              // @ts-ignore
              mapboxApiAccessToken={window.env.MAPBOX_ACCESS_TOKEN || ''}
            />
          </DeckGL>
          {hoverInfo && <Tooltip info={hoverInfo} />}
        </div>
      ) : (
        <Skeleton height={600} />
      )}
    </>
  )
}

export default Map
