import React, {FC} from 'react'
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

type Props = {
  number: number
  isActive: boolean
  onClick: () => void | undefined
}

const ShowMoreGuildsButton: FC<Props> = ({number, isActive, onClick}) => {
  return (
    <div onClick={onClick} className='d-flex align-items-center btn'>
      {isActive ? (
        <FontAwesomeIcon icon={faChevronUp} id='showMoreIcon' />
      ) : (
        <FontAwesomeIcon icon={faChevronDown} id='showMoreIcon' />
      )}
      <p className='text-white mb-0' style={{marginLeft: 10}}>
        Show {number} guilds {isActive ? 'less' : 'more'}
      </p>
    </div>
  )
}

export default ShowMoreGuildsButton
