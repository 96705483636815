/**
 * Returns the node label from the passed node type, or returns an empty string if node name could not be extracted
 * @param {string} nodeType
 * @return {string} Node Label
 */
export default function getNodeLabel(nodeType: string): string {
  if (nodeType.startsWith('Node')) {
    return nodeType.substring(4, nodeType.length)
  } else {
    return ''
  }
}
