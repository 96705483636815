import {gql} from '@apollo/client'

export const ALL_VALIDATION_BY_IDS = gql`
  query ($guild: String!, $date: Date!) {
    getAllValidationIds(guild: $guild, date: $date) {
      id
      validation_date
    }
  }
`
