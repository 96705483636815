import {getUrlParams} from 'helpers/getUrlParams'
import moment from 'moment'
import React, {FC, useState} from 'react'
import {DateRange, Range, RangeKeyDict} from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './style.scss'
import {useHistory, useLocation} from 'react-router-dom'

interface Props {
  onChange: (dateValue: DateItem, dateRange: Range) => void
  defaultDateRange: Range
}

const DateRangeCalendar: FC<Props> = ({onChange, defaultDateRange}) => {
  const [dateRange, setDateRange] = useState<Range[]>([defaultDateRange])
  const history = useHistory()
  const {search} = useLocation()

  const handleChange = (i: RangeKeyDict) => {
    setDateRange([i.selection])
    onChange(
      {
        label: 'calendar',
        parameter: [
          moment(i.selection.endDate).format('YYYY-MM-DD'),
          moment(i.selection.startDate).format('YYYY-MM-DD'),
        ],
      },
      i.selection
    )
    const query = getUrlParams(search)
    let currentQuery = `start=${moment(i.selection.startDate).format('YYYY-MM-DD')}&end=${moment(i.selection.endDate).format('YYYY-MM-DD')}`
    currentQuery = query ? query + '&' + currentQuery : '?' + currentQuery
    history.replace({search: currentQuery})
  }

  return (
    <div className='dateRangeCalendar'>
      <DateRange
        maxDate={moment().toDate()}
        onChange={handleChange}
        moveRangeOnFirstSelection={false}
        ranges={dateRange}
      />
    </div>
  )
}

export default DateRangeCalendar
