import React, {useEffect, useRef, useState} from 'react'

/**
 * Returns a boolean value, tracking the change in the width of the passed element, if the element's width is less than the maximum width, the function returns false, otherwise true
 * @param {React.RefObject<HTMLDivElement>} ref
 * @param {number} maxWidth
 * @return {boolean}
 */
export default function useMediaQuery(ref: React.RefObject<HTMLDivElement>, maxWidth: number): boolean {
  const [res, setRes] = useState(true)

  const observer = useRef(
    new ResizeObserver((entries) => {
      const {width} = entries[0].contentRect
      width <= maxWidth ? setRes(false) : setRes(true)
    })
  )

  useEffect(() => {
    ref.current && observer.current.observe(ref.current)
  }, [ref, observer])

  return res
}
