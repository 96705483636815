import React, {FC, createContext, useContext, useEffect, useState} from 'react'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  title?: string
  setTitle: (_title: string) => void
  pageTitle?: string
  setPageTitle: (_pageTitle: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  subtext?: string
  setSubtext: (_subtext: string) => void
  icon?: string
  setIcon: (_icon: string) => void
  loading?: boolean
  setLoading: (_loading: boolean) => void
  showFilter?: boolean
  setShowFilter: (_showFilter: boolean) => void
  link?: string
  setLink: (_link: string) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setTitle: (_title: string) => {},
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setSubtext: (_subtext: string) => {},
  setIcon: (_icon: string) => {},
  setLoading: (_loading: boolean) => {},
  setShowFilter: (_showFilter: boolean) => {},
  setLink: (_link: string) => {}
})

const PageDataProvider: React.FC = ({children}) => {
  const [title, setTitle] = useState<string>('')
  const [pageTitle, setPageTitle] = useState<string>('Validationcore')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const [subtext, setSubtext] = useState('')
  const [icon, setIcon] = useState('')
  const [loading, setLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [link, setLink] = useState('')
  const value: PageDataContextModel = {
    title,
    setTitle,
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    subtext,
    setSubtext,
    icon,
    setIcon,
    loading,
    setLoading,
    showFilter,
    setShowFilter,
    link,
    setLink
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  pageTitle?: string
  description?: string
  breadcrumbs?: Array<PageLink>
  subtext?: string
  icon?: string
  loading?: boolean
  showFilter?: boolean
  link?: string
}

const PageData: FC<Props> = ({children, pageTitle, description, breadcrumbs, subtext, icon, loading, showFilter, link}) => {
  const {
    setTitle,
    setPageTitle,
    setPageDescription,
    setPageBreadcrumbs,
    setSubtext,
    setIcon,
    setLoading,
    setShowFilter,
    setLink
  } = usePageData()
  useEffect(() => {
    if (pageTitle) {
      setPageTitle(pageTitle)
    }
    return () => {
      setPageTitle('Validationcore')
    }
  }, [pageTitle])

  useEffect(() => {
    if (children) {
      setTitle(children.toString())
    }
    return () => {
      setTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  useEffect(() => {
    if (subtext) {
      setSubtext(subtext)
    }
    return () => {
      setSubtext('')
    }
  }, [subtext])

  useEffect(() => {
    if (icon) {
      setIcon(icon)
    }
    return () => {
      setIcon('')
    }
  }, [icon])

  useEffect(() => {
    if (loading) {
      setLoading(loading)
    }
    return () => {
      setLoading(false)
    }
  }, [loading])

  useEffect(() => {
    if (showFilter) {
      setShowFilter(showFilter)
    }
    return () => {
      setShowFilter(false)
    }
  }, [showFilter])

  useEffect(() => {
    if (link) {
      setLink(link)
    }
    return () => {
      setLink('')
    }
  }, [link])

  return <></>
}

const PageDescription: React.FC = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, PageData, PageDataProvider, usePageData}
