/**
 * Returns an object with 'params' (FilterParams) and 'type' (FilterType) fields if the query passes validation (e.g. ?top21=t&seed=[32;100]&api=[46;100]) or null if validation failed
 * @param {string} query (e.g. ?top21=t&seed=[32;100]&api=[46;100])
 * @param {FilterType} filterType
 * @param {RegExp | undefined | false} itemVerification
 * @param {RegExp | undefined | false} optionVerification
 * @param {RegExp | undefined | false} multiOptionsVerification
 * @param {RegExp | undefined | false} rangeVerification
 * @param {number[] | false} rangeConfig
 * @return {{params: FilterParams, type: FilterType} | null}
 */
export const getFilterParams = (query: string, filterType: FilterType, itemVerification?: RegExp | false, optionVerification?: RegExp | false, multiOptionsVerification?: RegExp | false,  rangeVerification?: RegExp | false,  rangeConfig?: number[] | false): {params: FilterParams, type: FilterType} | null => {
  let currentFilterParams: {params: FilterParams, type: FilterType} | null = null
  const ranges: RangeItem[] = []
  const selectedItems: string[] = []
  let selectedOption: FilterItem | undefined
  const multiSelectedOptions: FilterItem[] = []
  const elementQueries = query.replace('?', '').split('&').join().replace(/;/g, ',').split(',')
  const optionQueries = query.replace('?', '').split('&').join().replace(/;/g, ',').split(',')
  const multiOptionsQueries = new URLSearchParams(query).get('version')
  const rangeQueries = query.split('&')
  if (itemVerification && elementQueries.length) {
    const itemParams = elementQueries.filter(i => i.match(itemVerification))
    if (itemParams.length) {
      itemParams.forEach(i => {
        if (i.match(/=t/)) {
          selectedItems.push(i.replace(/\?|=t/g, ''))
        } else if (i.match(/=f/)) {
          selectedItems.push(i.replace(/\?/g, ''))
        }
        else {
          return null
        }
      })
    }
  }
  if (optionVerification && optionQueries.length) {
    const optionParams = optionQueries.filter(i => i.match(optionVerification))
    if (optionParams.length === 1) {
      const value = optionParams[0].split('=')[1]
      if (value) {
        selectedOption = {
          value,
          label: value,
          type: 'select'
        }
      } else {
        return null
      }
    }
  }
  if (multiOptionsVerification && multiOptionsQueries) {
    if (multiOptionsQueries.match( /^\[/) && multiOptionsQueries.match( /]$/)) {
      const multiOptionParams = multiOptionsQueries.replace(/\[|]/g, '').split(';').filter(i => i.match(multiOptionsVerification))
      if (multiOptionParams.length) {
        multiOptionParams.forEach(i => {
          multiSelectedOptions.push({
            type: 'select',
            value: i,
            label: i
          })
        })
      }
    }
  }
  if (rangeQueries.length && rangeVerification) {
    const rangeParams = rangeQueries.filter(i => i.match(rangeVerification))
    if (rangeParams && rangeParams.length && rangeConfig) {
      rangeParams.forEach(i => {
        const name = i.split('=')[0].replace(/\?/, '')
        const values = i.split('=')[1].replace(/^.|.$/g, '').split(';').map(i => Number(i))
        if (values[0] >= rangeConfig[0] && (values.length === 2 ? values[0] < values[1] : values[0] <= rangeConfig[1])) {
          ranges.push({name, values})
        } else {
          return null
        }
      })
    }
  }

  switch (filterType) {
    case 'range':
      if (ranges.length) {
        currentFilterParams = {params: {ranges}, type: filterType}
      }
      break
    case 'switch':
      if (selectedItems.length || multiSelectedOptions.length) {
        if (multiSelectedOptions.length) {
          currentFilterParams = {params: {selectedItems, multiSelectedOptions}, type: filterType}
        } else {
          currentFilterParams = {params: {selectedItems}, type: filterType}
        }
      }
      break
    case 'multi':
      currentFilterParams = {params: {selectedItems, ranges, selectedOption, multiSelectedOptions}, type: filterType}
      break
  }

  return currentFilterParams
}
