import React, {FC} from 'react'
import {Link} from 'react-router-dom'

type Props = {
  image: string
  title: string
  link: string
}

const Tile: FC<Props> = ({title, link}) => {
  return (
    <Link to={link}>
      <div className='card h-100 card-hover'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0' />
        </div>

        <div className='card-body d-flex flex-column px-9 pt-6 pb-8'>
          <div className='fs-2tx fw-bolder text-white mb-3'>{title}</div>
        </div>
      </div>
    </Link>
  )
}

export default Tile
