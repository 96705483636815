import {throttle} from 'lodash'

/**
 * Creates a throttled function that only invokes callback at most once per every wait milliseconds
 * @param {any} callback
 * @param {number} wait
 * @param {options} options
 * @return {any} callback
 */
export default function useThrottle(
  callback: (...args: any) => any,
  wait = 1300,
  options: {leading?: boolean; trailing?: boolean} = {leading: true, trailing: true}
): any {
  return throttle(callback, wait, options)
}
