import {KTSVG} from '_metronic/helpers'
import cn from 'classnames'
import {getUrlParams} from 'helpers/getUrlParams'
import _ from 'lodash'
import React, {FC, useState, Fragment, useEffect, ReactNode} from 'react'
import './style.scss'
import {Hint, RangeSlider} from 'components'
import {MultiSelect} from 'react-multi-select-component'
import {useHistory, useLocation} from 'react-router-dom'

interface Props {
  content?: FilterContent[]
  filterType?: FilterType
  currentSelectedItems?: string[]
  defaultSelectedItems?: string[]
  currentRanges?: RangeItem[]
  defaultRanges?: RangeItem[]
  defaultSelectedOption?: FilterItem
  currentSelectedOption?: FilterItem
  defaultMultiSelectedOptions?: FilterItem[]
  onReset?: () => void
  onSubmit?: (params: FilterParams, type: FilterType) => void
  showModal?: boolean
  dateFilter?: ReactNode
  hideModal: () => void
}

interface Option {
  value: any;
  label: string;
  key?: string;
  disabled?: boolean;
}

const FilterModal: FC<Props> = ({
  content,
  onSubmit,
  filterType,
  onReset,
  showModal,
  currentRanges,
  defaultRanges,
  defaultSelectedItems,
  currentSelectedItems,
  currentSelectedOption,
  defaultSelectedOption,
  defaultMultiSelectedOptions,
  dateFilter,
  hideModal
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>(currentSelectedItems || [])
  const [selectedOption, setSelectedOption] = useState<FilterItem | undefined>(currentSelectedOption)
  const [multiSelectedOptions, setMultiSelectedOptions] = useState<FilterItem[]>(defaultMultiSelectedOptions || [])
  const [filteredOptions, setFilteredOptions] = useState<FilterItem[]>([])
  const [ranges, setRanges] = useState<RangeItem[]>(currentRanges || [])
  const history = useHistory()
  const {search} = useLocation()
  const [hideContent, setHideContent] = useState(true)

  useEffect(() => {
    currentRanges && setRanges(currentRanges)
    currentSelectedItems && setSelectedItems(currentSelectedItems)
    currentSelectedOption && setSelectedOption(currentSelectedOption)
    defaultMultiSelectedOptions && setMultiSelectedOptions(defaultMultiSelectedOptions)
  }, [currentRanges, currentSelectedItems, currentSelectedOption, defaultMultiSelectedOptions])

  useEffect(() => {
    currentRanges && setRanges(currentRanges)
    currentSelectedItems && setSelectedItems(currentSelectedItems)
    currentSelectedOption && setSelectedOption(currentSelectedOption)
    defaultMultiSelectedOptions && setMultiSelectedOptions(defaultMultiSelectedOptions)
  }, [showModal])

  const handleSelect = (id: string) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((i) => i !== id))
    } else {
      setSelectedItems((current) => [...current, id])
    }
  }

  const handleSelectOption = (value: string) => {
    content?.forEach(i => {
      if (i.isSelect) {
        i.elements.some(el => el.value === value && setSelectedOption(el))
      }
    })
  }

  const handleMultiSelectOption = (i: FilterItem, isAll?: string | boolean) => {
    if (isAll && content) {
      if (isAll === 'all') {
        const currentOptions: FilterItem[] = []
        const defaultOption: FilterItem[] = []
        content.forEach(i => i.isMultiSelect && defaultOption.push(...i.elements))
        if (filteredOptions.length < defaultOption.length) {
          filteredOptions.forEach(i => currentOptions.push(i))
        } else {
          defaultOption.forEach(i => currentOptions.push(i))
        }
        setMultiSelectedOptions(currentOptions)
      } else {
        setMultiSelectedOptions([])
      }
      return
    }
    if (multiSelectedOptions.some(el => el.label === i.label)) {

      setMultiSelectedOptions(current => current.filter((el) => el.value !== i.value))
    } else {
      setMultiSelectedOptions((current) => [...current, i])
    }
  }

  const handleReset = () => {
    setSelectedItems(currentSelectedItems || [])
    currentRanges && setRanges(currentRanges)
    setSelectedOption(currentSelectedOption)
    setMultiSelectedOptions([])
    const query = getUrlParams(search, true)
    history.replace({search: query || ''})
    onReset && onReset()
  }

  const getParams = () => {
    let params = ''
    const range = defaultRanges && !_.isEqual(ranges, defaultRanges) &&
      _.differenceBy(ranges, defaultRanges, 'values')?.map(i => {
        if (i.values.length === 2) {
         return `${ i.name.toLowerCase() }=[${ i.values[0] };${ i.values[1] }]`
        } else {
          return `${ i.name.toLowerCase() }=[${ i.values[0] }]`
        }
      }).join('&')

    if (selectedItems.length || defaultSelectedItems?.length) {
      const defaultItems = _.difference(defaultSelectedItems, selectedItems)
      const items = _.difference(selectedItems, defaultSelectedItems || [])
      if (defaultItems.length && defaultSelectedItems?.length) {
        params = defaultItems.join('=f;').toLowerCase() + '=f'
      }
      if (items.length) {
        params = params.length ? params + ';' + items.join('=t;').toLowerCase() + '=t' : items.join('=t;').toLowerCase() + '=t'
      }
    }
    if (selectedOption) {
      if (!defaultSelectedOption || defaultSelectedOption.value !== selectedOption.value) {
        const option = 'querymethod=' + selectedOption.value
        params = params.length ? params + '&' + option : option
      }
    }
    if (multiSelectedOptions.length) {
      const version = `version=[${multiSelectedOptions.map(i => i.value).join(';')}]`
      params = params.length ? params + '&' + version : version
    }
    if (range && range.length) {
      params = params.length ? params + '&' + range : range
    }
    return params
  }

  const handleSubmit = () => {
    if (filterType && onSubmit) {
      let params
      let currentQuery = getParams()
      const query = getUrlParams(search, true)
      switch (filterType) {
        case "switch":
          if (multiSelectedOptions.length) {
            params = {selectedItems, multiSelectedOptions}
          } else {
            params = {selectedItems}
          }
          break
        case "range":
          params = {ranges}
          break
        default:
          params = {selectedItems, ranges, multiSelectedOptions: multiSelectedOptions.length ? multiSelectedOptions : undefined, selectedOption}
      }
      if (query) {
        currentQuery = currentQuery ? query + '&' + currentQuery : query
      } else {
        currentQuery = currentQuery && '?' + currentQuery
      }

      onSubmit(params, filterType)
      history.replace({search: currentQuery})
    }
  }

  const handleChangeRange = (values: number[], name: string) => {
    setRanges((current) =>
      current.map((i) => {
        if (i.name === name) return {...i, values}
        return i
      })
    )
  }

  const handleFilter = (options: Option[], filter: string) => {
    const filteredOptions: Option[] = []
    options.forEach(i => {
      i.label.includes(filter) && filteredOptions.push(i)
    })

    setFilteredOptions(filteredOptions as FilterItem[])

    return filteredOptions
  }

  return (
    <>
      <div className='filterModal menu menu-sub menu-sub-dropdown w-250px w-md-300px'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        <div className='separator border-gray-200' />
        <div className='px-7 py-5'>
          {dateFilter && (
            <div className='mb-4'>
              <p className='form-label fw-bold mb-3'>Date range</p>
              {dateFilter}
            </div>
          )}
          {content &&
          content.map((i) => (
            <div className='mb-4' key={i.label + '-' + i.elements.length}>
              {i.header &&
                <div className={cn('d-flex mb-3 align-items-center', {'cursor-pointer': i.hidden})} onClick={() => i.hidden && setHideContent(!hideContent)}>
                  <p className='form-label fw-bold mb-0 me-1'>{i.header}</p>
                  {i.hidden &&
                    <KTSVG path={'/media/icons/duotune/arrows/arr074.svg'} className={cn('svg-icon-muted svg-icon-1 fa-rotate-90 transition', {'fa-rotate-270': hideContent})} />
                  }
                </div>
              }
              {i.label &&
                <div className={cn('d-flex mb-3 align-items-center', {'hideContent': i.hidden && hideContent}) }>
                  <p className={cn('mb-0', {'text-gray-700 fs-13': i.secondaryLabel, 'form-label fw-bold': !i.secondaryLabel})}>{i.label}</p>
                  {i.hint &&
                    <div className='ml-1'>
                      <Hint description={i.hint}/>
                    </div>
                  }
                </div>
              }
              <div className={cn('d-flex flex-column', {'hideContent': i.hidden && hideContent})}>
                {i.isMultiSelect ?
                    <MultiSelect
                      onChange={() => setMultiSelectedOptions([])}
                      options={i.elements as Option[]}
                      value={multiSelectedOptions as Option[]}
                      labelledBy='Select'
                      className='multiSelect'
                      filterOptions={handleFilter}
                      ItemRenderer={(i: any) =>
                        <div className='cursor-default form-check form-check-custom form-check-solid form-check-sm'>
                          <input type='checkbox' className='form-check-input' checked={i.checked} onChange={() => handleMultiSelectOption(i.option, i.option.value === '' && (i.checked ? 'nothing' : 'all'))}/>
                          <label className='form-check-label' htmlFor='flexCheckDefault'>
                            { i.option.label }
                          </label>
                        </div>
                      }
                    />
                  : i.isSelect ?
                    <div className='w-100'>
                      <select
                        className='select form-select form-select-solid btn-label'
                        value={selectedOption?.value}
                        onChange={({target}) => handleSelectOption(target.value)}
                      >
                        {i.elements.map((i) => (
                          <option key={i.value + i.label} value={i.value}>
                            {i.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  : i.elements.map((el) => (
                      <Fragment key={el.label + el.value}>
                        {el.type === 'checkbox' ? (
                          <label className='form-check form-check-sm form-check-custom form-check-solid me-6 mb-4'>
                            <input
                              className='form-check-input'
                              checked={selectedItems.includes(el.value)}
                              type='checkbox'
                              name={el.label}
                              onChange={() => handleSelect(el.value)}
                            />
                            <span className='form-check-label'>{el.label}</span>
                          </label>
                        ) : el.type === 'switch' ? (
                          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-4'>
                            <input
                              className='form-check-input mr-2'
                              type='checkbox'
                              onChange={() => handleSelect(el.value)}
                              checked={selectedItems.includes(el.value)}
                              name={el.label}
                            />
                            <label className='form-check-label'>{el.label}</label>
                            {el.hint &&
                              <div className='ml-1'>
                                <Hint description={el.hint}/>
                              </div>
                            }
                          </div>
                        ) : (
                          (el.type === 'rangeSlider' || el.type === 'secondaryRangeSlider') &&
                          el.sliderConfig &&
                          (
                            <div className='mb-4'>
                              {el.label &&
                                <div className='d-flex align-items-center mb-2'>
                                  <label className='form-check-label'>{el.label}</label>
                                  {el.hint &&
                                  <div className='ml-1'>
                                    <Hint description={el.hint}/>
                                  </div>
                                  }
                                </div>
                              }
                              <RangeSlider
                                secondary={el.type === 'secondaryRangeSlider'}
                                values={ranges[ranges.findIndex((i) => i.name === el.value)].values}
                                step={el.sliderConfig.step}
                                minValue={el.sliderConfig.minValue}
                                maxValue={el.sliderConfig.maxValue}
                                onChange={(values) => handleChangeRange(values, el.value)}
                                unit={el.sliderConfig.unit}
                              />
                            </div>
                          )
                        )}
                      </Fragment>
                    ))
                }
              </div>
            </div>
          ))}
          <div className='d-flex justify-content-end mt-7'>
            {onReset && (
              <button
                onClick={handleReset}
                type='reset'
                className='btn btn-sm btn-white btn-active-light-primary me-2 button-active'
              >
                Reset
              </button>
            )}
            {onSubmit && (
              <button onClick={handleSubmit} className='btn btn-sm btn-primary button-active'>
                Apply
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="maskLayer" onClick={hideModal}/>
    </>
  )
}

export default FilterModal
