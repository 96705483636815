import {SyntaxHighlighter} from 'components'
import {ChainItem, getChainItem, getCurrentChainId} from 'config/chains'
import genesisMn from 'assets/files/genesis-wax-mn.json'
import genesisTn from 'assets/files/genesis-wax-tn.json'
import React from 'react'

const genesisStyles = {
  "hljs-attr": {
    "color": "#70b0ff"
  },
  "hljs-number": {
    "color": "#7af8ca"
  },
  "hljs-string": {
    "color": "#7af8ca"
  },
  "code[class*=\"language-\"]": {
    "color": "#b4c2f0"
  }
}

const chainStyles = {
  "code[class*=\"language-\"]": {
    "color": "#70b0ff"
  }
}

const Overview: React.FC = () => {
  const data = JSON.stringify(
    getChainItem(ChainItem.name) === 'WAX Mainnet' ? genesisMn : genesisTn, null, "\t"
  )

  return (
    <div>
      <div className="mb-5">
        <SyntaxHighlighter data={[getCurrentChainId()]} header='Chain id' style={chainStyles}/>
      </div>
      <div>
        <SyntaxHighlighter data={[data]} header='genesis.json' fileName='genesis.json' language='json' style={genesisStyles}/>
      </div>
    </div>
  )
}

export default Overview
