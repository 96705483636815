import React, {FC} from 'react'

type Props = {
  header: string
  text: string
}

const GuildListInfo: FC<Props> = ({header, text}) => {
  return (
    <div className='card px-6 py-8 mb-9'>
      <h1>{header}</h1>
      <p className='text-gray-700'>{text}</p>
    </div>
  )
}

export default GuildListInfo
