import {NetworkStatus, useLazyQuery} from '@apollo/client'
import {PageData} from '_metronic/layout/core'
import cn from 'classnames'
import {DateFilter, DateRangeCalendar, Button, FilterModal, NoDataAvailable, Toolbar} from 'components'
import {ContentType, defaultDateContent, defaultDateValue} from 'consts'
import {getDateValue} from 'helpers/getDateValue'
import {getFilterParams} from 'helpers/getFilterParams'
import useOnClickOutside from 'hooks/useOnClickOutside'
import _ from 'lodash'
import moment from 'moment'
import {GET_GUILDS} from 'queries/getGuilds'
import React, {FC, Fragment, useEffect, useRef, useState} from 'react'
import {Range} from 'react-date-range'
import Skeleton from 'react-loading-skeleton'
import {useMediaQuery} from 'react-responsive'
import {useHistory, useLocation} from 'react-router-dom'
import {GuildListInfo, GuildRow, ShowMoreButton} from './components'

type Props = {
  contentType: ContentType
}

const vElements: FilterItem[] = [
  {type: 'checkbox', label: 'Organization', value: 'organization'},
  {type: 'checkbox', label: 'Seed', value: 'seed'},
  {type: 'checkbox', label: 'API', value: 'api'},
  {type: 'checkbox', label: 'Wallet', value: 'wallet'},
  {type: 'checkbox', label: 'History v1', value: 'history'},
  {type: 'checkbox', label: 'Hyperion v2', value: 'hyperion'},
  {type: 'checkbox', label: 'Atomic', value: 'atomic'},
]

const sliderConfig = {step: 1, minValue: 0, maxValue: 100}

const sElements: FilterItem[] = [
  {type: 'rangeSlider', label: 'Seed', value: 'seed', sliderConfig},
  {type: 'rangeSlider', label: 'API', value: 'api', sliderConfig},
  {type: 'rangeSlider', label: 'Wallet', value: 'wallet', sliderConfig},
  {type: 'rangeSlider', label: 'History v1', value: 'history', sliderConfig},
  {type: 'rangeSlider', label: 'Hyperion v2', value: 'hyperion', sliderConfig},
  {type: 'rangeSlider', label: 'Atomic', value: 'atomic', sliderConfig},
]

const defaultRanges: RangeItem[] = [
  {name: 'seed', values: [0, 100]},
  {name: 'api', values: [0, 100]},
  {name: 'wallet', values: [0, 100]},
  {name: 'history', values: [0, 100]},
  {name: 'hyperion', values: [0, 100]},
  {name: 'atomic', values: [0, 100]},
]

const GuildList: FC<Props> = ({contentType}) => {
  const title = contentType === ContentType.Validation ? 'Guild Validations' : 'Guild Availability Statistics'
  const [getGuild, {data, error, refetch, called, networkStatus}] = useLazyQuery<QueryGuildsRes>(GET_GUILDS, {
    notifyOnNetworkStatusChange: true
  })
  const [guilds, setGuilds] = useState<{guildsWithIcon: Guild[]; guildsWithoutIcon: Guild[]}>({
    guildsWithIcon: [],
    guildsWithoutIcon: [],
  })
  const [refetchLoading, setRefetchLoading] = useState(false)
  const [lastTopRank, setLastTopRank] = useState(0)
  const [showMoreGuilds, setShowMoreGuilds] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [ranges, setRanges] = useState(defaultRanges)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [dateValue, setDateValue] = useState(defaultDateValue)
  const [currentFilter, setCurrentFilter] = useState<{params: FilterParams; type: FilterType} | null>(null)
  const [showCalendar, setShowCalendar] = useState(false)
  const [dateRange, setDateRange] = useState<Range>({
    startDate: moment().toDate(),
    endDate: moment().subtract(1, 'months').toDate(),
    key: 'selection',
  })

  const {search} = useLocation()
  const history = useHistory()

  const modalRef = useRef(null)
  const calendarRef = useRef(null)

  const isValidation = contentType === ContentType.Validation
  const isSmallScreen = useMediaQuery({query: '(max-width: 767px)'})

  // Filter modal
  useOnClickOutside(modalRef, () => {
    isSmallScreen ? !showCalendar && setShowModal(false) : setShowModal(false)
  })
  // Calender Modal
  useOnClickOutside(calendarRef, () => setShowCalendar(false))

  const toggleMoreGuilds = () => {
    setShowMoreGuilds(!showMoreGuilds)
  }

  const sortGuildsDefault = () => {
    if (data) {
      const sortedGuilds = divideGuilds(sortGuildsAfterRank([...data.getGuilds]))
      setGuilds({guildsWithIcon: sortedGuilds.guildsWithIcon, guildsWithoutIcon: sortedGuilds.guildsWithoutIcon})
    }
  }

  const sortGuildsAfterRank = (guilds: Guild[]): Guild[] => {
      guilds.sort((x, y) => {
        if (x.rank && y.rank) return x.rank - y.rank
        return 0
      })
      return guilds;
  }

  const divideGuilds = (guilds: Guild[]): {guildsWithIcon: Guild[], guildsWithoutIcon: Guild[]} => {
    const guildsWithIcon: Guild[] = []
    const guildsWithoutIcon: Guild[] = []
    guilds.forEach((guild) => {
      if (
        guild.url_logo_256 ||
        (guild.rank && guild.rank <= 21) ||
        (isValidation ? Object.values(guild.validation_levels).some(i => i === "SUCCESS") : Object.values(guild.stats_availability).some(i => i >= 1))
      ) {
        guildsWithIcon.push(guild)
      } else {
        guildsWithoutIcon.push(guild)
      }
    })
    return {guildsWithIcon, guildsWithoutIcon}
  }

  useEffect(() => {
    called && refetch({
      startDate: defaultDateValue.parameter[1],
      endDate: defaultDateValue.parameter[0],
    })
    setRanges(defaultRanges)
    setSelectedItems([])
    setDateValue(defaultDateValue)
    setCurrentFilter(null)
    sortGuildsDefault()
  }, [isValidation])

  useEffect(() => {
    if (data?.getGuilds) {
      (networkStatus === NetworkStatus.setVariables || networkStatus === NetworkStatus.ready) && setRefetchLoading(false)
    } else {
      networkStatus === NetworkStatus.setVariables && setRefetchLoading(true)
    }
    if (currentFilter) {
      handleFilter(currentFilter.params, currentFilter.type)
    } else {
      sortGuildsDefault()
    }
  }, [data])

  const handleFilter = (params: FilterParams, type: FilterType) => {
    if (data) {
      // Sort after Rank
      let sortedGuilds = sortGuildsAfterRank([...data.getGuilds])

      // apply filter
      if (type === 'switch' || type === 'multi') {

        /**
         * GuildList for Validations
         */
        if (params.selectedItems) {
          const modifiedParams = params.selectedItems

          // Filter Top21
          if (modifiedParams.includes('top21')) {
            sortedGuilds = sortedGuilds.filter((guild) => guild.rank && guild.rank <= 21)
          }

          // Filter Organization Status
          if (modifiedParams.includes('organization')) {
            sortedGuilds = sortedGuilds.filter((guild) => guild.validation_levels && guild.validation_levels.organization === "SUCCESS")
          }

          // Filter Seed Status
          if (modifiedParams.includes('seed')) {
            sortedGuilds = sortedGuilds.filter((guild) => guild.validation_levels && guild.validation_levels.node_seed === "SUCCESS")
          }

          // Filter Api Status
          if (modifiedParams.includes('api')) {
            sortedGuilds = sortedGuilds.filter((guild) => guild.validation_levels && guild.validation_levels.node_api === "SUCCESS")
          }

          // Filter Wallet Status
          if (modifiedParams.includes('wallet')) {
            sortedGuilds = sortedGuilds.filter((guild) => guild.validation_levels && guild.validation_levels.node_wallet === "SUCCESS")
          }

          // Filter History Status
          if (modifiedParams.includes('history')) {
            sortedGuilds = sortedGuilds.filter((guild) => guild.validation_levels && guild.validation_levels.node_history === "SUCCESS")
          }

          // Filter Hyperion Status
          if (modifiedParams.includes('hyperion')) {
            sortedGuilds = sortedGuilds.filter((guild) => guild.validation_levels && guild.validation_levels.node_hyperion === "SUCCESS")
          }

          // Filter Atomic Status
          if (modifiedParams.includes('atomic')) {
            sortedGuilds = sortedGuilds.filter((guild) => guild.validation_levels && guild.validation_levels.node_atomic === "SUCCESS")
          }

          if (type === 'switch') {
            setGuilds({guildsWithIcon: sortedGuilds, guildsWithoutIcon: []})
            setSelectedItems(params.selectedItems)
            setCurrentFilter({params, type})
          }
        } else {
          sortGuildsDefault()
          setSelectedItems([])
          setCurrentFilter(null)
        }
        /**
         * GuildList for Statistics
         */
        if (type === 'multi' && params.ranges && params.selectedItems) {
          for (const filter of params.ranges) {
            switch (filter.name) {
              case "seed":
                sortedGuilds = sortedGuilds.filter((guild) => guild.stats_availability && filter.values[0] <= (guild.stats_availability.node_seed || 0) && (guild.stats_availability.node_seed || 0) <= filter.values[1])
                break
              case "api":
                sortedGuilds = sortedGuilds.filter((guild) => guild.stats_availability && filter.values[0] <= (guild.stats_availability.node_api || 0) && (guild.stats_availability.node_api || 0) <= filter.values[1])
                break
              case "wallet":
                sortedGuilds = sortedGuilds.filter((guild) => guild.stats_availability && filter.values[0] <= (guild.stats_availability.node_wallet || 0) && (guild.stats_availability.node_wallet || 0) <= filter.values[1])
                break
              case "history":
                sortedGuilds = sortedGuilds.filter((guild) => guild.stats_availability && filter.values[0] <= (guild.stats_availability.node_history || 0) && (guild.stats_availability.node_history|| 0) <= filter.values[1])
                break
              case "hyperion":
                sortedGuilds = sortedGuilds.filter((guild) => guild.stats_availability && filter.values[0] <= (guild.stats_availability.node_hyperion || 0) && (guild.stats_availability.node_hyperion || 0) <= filter.values[1])
                break
              case "atomic":
                sortedGuilds = sortedGuilds.filter((guild) => guild.stats_availability && filter.values[0] <= (guild.stats_availability.node_atomic || 0) && (guild.stats_availability.node_atomic || 0) <= filter.values[1])
                break
            }
            }

          setGuilds({guildsWithIcon: sortedGuilds, guildsWithoutIcon: []})
          setSelectedItems(params.selectedItems)
          setRanges(params.ranges)
          setCurrentFilter({params, type})
        }
        if (type === 'multi' && Array.isArray(params)) {
          sortGuildsDefault()
          setRanges(defaultRanges)
          setCurrentFilter(null)
        }
      }
      setShowModal(false)
    }
  }

  useEffect(() => {
    let rank = 0
    guilds.guildsWithIcon.forEach((guild) => guild.rank && guild.rank <= 21 && (rank = guild.rank))
    setLastTopRank(rank)
  }, [guilds.guildsWithIcon])

  const handleReset = () => {
    sortGuildsDefault()
    setRanges(defaultRanges)
    setSelectedItems([])
    setCurrentFilter(null)
  }

  useEffect(() => {
    if (search) {
      const itemVerification = new RegExp(vElements.map(i => i.value.toLowerCase()).join('=t|') + '=t|' + 'top21=t')
      const rangeVerification = !isValidation && new RegExp(sElements.map(i => i.value.toLowerCase().replace('node', '')).join('=\\[([0-9]|[1-9][0-9]);([1-9]|[1-9][0-9]|100)\\]|') + '=\\[([0-9]|[1-9][0-9]);([1-9]|[1-9][0-9]|100)\\]')
      const currentDateValue = !isValidation && getDateValue(search)
      const rangeConfig = !isValidation && [sliderConfig.minValue, sliderConfig.maxValue]
      const currentFilterParams = getFilterParams(search, isValidation ? 'switch' : 'multi', itemVerification, false, false, rangeVerification, rangeConfig)
      if (currentFilterParams) {
       if (currentFilterParams.type === 'multi') {
         if (currentFilterParams.params.ranges && currentFilterParams.params.selectedItems) {
           setCurrentFilter(
             {
               params: {selectedItems: currentFilterParams.params.selectedItems, ranges: [...currentFilterParams.params.ranges, ..._.differenceBy(ranges, currentFilterParams.params.ranges, 'name')]},
               type: currentFilterParams.type
             }
           )
         }
       } else {
         setCurrentFilter(currentFilterParams)
       }
      }
      if (currentDateValue) {
        setDateValue(currentDateValue)
        setDateRange((current) => {
          return {
            ...current,
            startDate: moment(currentDateValue.parameter[0]).toDate(),
            endDate: moment(currentDateValue.parameter[1]).toDate(),
          }
        })
        if (!data) {
          if (currentDateValue.queryParams === 'all') {
            getGuild({variables: {startDate: null, endDate: null}})
          } else {
            getGuild({
              variables: {
                startDate: currentDateValue.parameter[1],
                endDate: currentDateValue.parameter[0]
              }
            })
          }
          return
        }
      }
      if (!currentFilterParams && !currentDateValue) {
        history.replace({search: ''})
      }
    }
    if (!called && !data) {
      getGuild({variables: {
          startDate: defaultDateValue.parameter[1],
          endDate: defaultDateValue.parameter[0],
        }})
    }
  }, [search, isValidation])

  const handleDateValueChange = (dateValue: DateItem, dateRange?: Range) => {
    setRefetchLoading(true)
    if (dateValue.parameter.length === 2) {
      refetch(
      {
        startDate: dateValue.parameter[1],
        endDate: dateValue.parameter[0]
      }).then(() => setRefetchLoading(false))
    } else {
      refetch({
        startDate: null,
        endDate: null
      }).then(() => setRefetchLoading(false))
    }
    if (!isSmallScreen) {
      currentFilter
        ? handleFilter(currentFilter.params, currentFilter.type)
        : sortGuildsDefault()
    }
    dateRange
      ? setDateRange(dateRange)
      : setDateRange((current) => {
          return {
            ...current,
            startDate: moment(dateValue.parameter[0]).toDate(),
            endDate: moment(dateValue.parameter[1]).toDate(),
          }
        })
    setDateValue(dateValue)
  }

  return (
    <>
      <PageData
        link='/overview'
        showFilter
        pageTitle={title}
      >
        {title}
      </PageData>
      <Toolbar
        rightChildren={
          <>
            {!isValidation && refetchLoading &&
              <span className="spinner-border align-middle ms-2"/>
            }
            {!isValidation && !isSmallScreen && (
              <DateFilter
                loading={networkStatus === NetworkStatus.loading}
                onChange={handleDateValueChange}
                content={defaultDateContent}
                defaultDateValue={dateValue}
                showCalendar={() => setShowCalendar(true)}
              />
            )}
            <Button
              title="Filter"
              pulse
              icon='/general/gen031.svg'
              loading={!isValidation ?
                  networkStatus === NetworkStatus.loading
                :
                  networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables
              }
              onClick={() => setShowModal(true)}
              disabled={networkStatus === NetworkStatus.loading || !!error}
            />
            <div className={cn('renderModal', {show: showModal})} ref={modalRef}>
              <FilterModal
                content={[
                  {label: 'Guild', elements: [{type: 'switch', label: 'Top21', value: 'top21'}]},
                  {
                    label: 'Validation Status',
                    elements: isValidation ? vElements : sElements,
                  },
                ]}
                currentRanges={ranges}
                defaultRanges={defaultRanges}
                currentSelectedItems={selectedItems}
                filterType={isValidation ? 'switch' : 'multi'}
                onReset={handleReset}
                onSubmit={handleFilter}
                showModal={showModal}
                dateFilter={
                  !isValidation &&
                  isSmallScreen && (
                    <DateFilter
                      onChange={handleDateValueChange}
                      content={defaultDateContent}
                      defaultDateValue={dateValue}
                      isMobile
                      showCalendar={() => setShowCalendar(true)}
                    />
                  )
                }
                hideModal={() => setShowModal(false)}
              />
            </div>
            {showCalendar && (
              <div className='calendarWrapper' ref={calendarRef}>
                <DateRangeCalendar onChange={handleDateValueChange} defaultDateRange={dateRange} />
              </div>
            )}
          </>
        }
      />
      <div className='align-self-center'>
        {(!isValidation && networkStatus === NetworkStatus.loading) || (isValidation && (networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables)) ? (
          <Skeleton height={110} count={60} className='mb-3' />
        ) : error ? (
          <NoDataAvailable />
        ) : (
          <>
            {contentType === ContentType.Validation ? (
              <GuildListInfo
                header={'Guild Validations'}
                text={
                  'All guilds are validated every 15 minutes. The list shows the status of the last validation. If a guild has multiple endpoints in the same category, all of those endpoints have to work in order to receive a "working" status. Hyperion nodes can be classified as full (= Full block history) or partial (= Last 42days of history).'
                }
              />
            ) : (
              <GuildListInfo
                header={'Guild Availability Statistics'}
                text={
                  'The shown percentage values refer to the uptime of an endpoint category. If a guild has multiple endpoints of the same category, the average will be taken. Each individual uptime percentage is calculated by dividing the number of successful validations by the number of total validations in a 24h timeframe. A low uptime can also be due to rate limitations or in case of a seed node due to filled up seed Nodes. Check our guild validations for more insights.'
                }
              />
            )}
            {!!guilds.guildsWithIcon.length &&
              guilds.guildsWithIcon.map((i) => {
                return (
                  <Fragment key={i.rank + i.name}>
                    <GuildRow guildObject={i} contentType={contentType} />
                    {lastTopRank === i.rank && <div className='separator border-2 m-10' />}
                  </Fragment>
                )
              })}
            {!guilds.guildsWithIcon.length && <NoDataAvailable title='Nothing found' />}
          </>
        )}
        {!!guilds.guildsWithoutIcon.length && (
          <div className='mt-5'>
            <ShowMoreButton
              isActive={showMoreGuilds}
              number={guilds.guildsWithoutIcon.length}
              onClick={toggleMoreGuilds}
            />
          </div>
        )}
        {showMoreGuilds &&
          guilds.guildsWithoutIcon.map((i) => (
            <GuildRow guildObject={i} contentType={contentType} key={i.rank + i.name} />
          ))}
      </div>
    </>
  )
}

export default GuildList
