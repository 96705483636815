import React from 'react'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {Error404, Error500} from './components'
import {toAbsoluteUrl} from '_metronic/helpers'
import Footer from 'components/blacklusion-footer/footer'

const ErrorsPage: React.FC = () => {
  const history = useHistory()
  const redirectToDashboard = () => {
    history.push('/')
  }

  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`}}
      >
        <div className='d-flex flex-column flex-column-fluid text-center justify-content-center p-10 py-lg-20 min-vh-100'>
          <a href='/dashboard' className='mb-10 pt-lg-20'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1-dark.svg')} className='h-50px' />
          </a>
          <div className='pt-lg-10 mb-10'>
            <Switch>
              <Route path='/error/404' exact={true}>
                <Error404 />
              </Route>
              <Route path='/error/500' exact={true}>
                <Error500 />
              </Route>
              <Redirect from='/error' exact={true} to='/error/404' />
              <Redirect to='/error/404' />
            </Switch>

            <div className='text-center'>
              <a onClick={redirectToDashboard} className='btn btn-lg btn-secondary fw-bolder'>
                Go to homepage
              </a>
            </div>
          </div>
        </div>
        <Footer pathsAreRelative={false} />
      </div>
    </div>
  )
}

export default ErrorsPage
