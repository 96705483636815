import moment from 'moment'
import {Range} from 'react-date-range'

export enum ContentType {
  Validation,
  Statistics,
}

export enum ValidationLevel {
  NULL,
  ERROR,
  WARN,
  INFO,
  SUCCESS,
  SUCCESSALL,
}

export enum NodeType {
  NODE_SEED = 'NODE_SEED',
  NODE_API = 'NODE_API',
  NODE_WALLET = 'NODE_WALLET',
  NODE_HISTORY = 'NODE_HISTORY',
  NODE_HYPERION = 'NODE_HYPERION',
  NODE_ATOMIC = 'NODE_ATOMIC',
}

export const defaultDateContent: DateItem[] = [
  {
    label: '7days',
    parameter: [moment().format('YYYY-MM-DD'), moment().subtract(7, 'days').format('YYYY-MM-DD')],
    queryParams: '7d',
  },
  {label: '30days', parameter: [moment().format('YYYY-MM-DD'), moment().subtract(1, 'months').format('YYYY-MM-DD')]},
  {label: 'All time', parameter: [], queryParams: 'all'},
]

export const defaultDateValue: DateItem = {
  label: '30days',
  parameter: [moment().format('YYYY-MM-DD'), moment().subtract(1, 'months').format('YYYY-MM-DD')],
}

export const defaultDateRange: Range = {
  startDate: moment().toDate(),
  endDate: moment().subtract(1, 'months').toDate(),
  key: 'selection',
}

export const defaultEndpointContent: FilterContent[] = [{
  label: 'Endpoint Status',
  elements: [
    {type: 'switch', label: 'SSL', value: 'ssl'},
    {type: 'switch', label: 'Only Working', value: 'working'},
  ]
}]
