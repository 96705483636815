import cn from 'classnames'
import React, {FC} from 'react'
import {Col} from 'react-bootstrap-v5'
import Skeleton from 'react-loading-skeleton'
import {KTSVG} from '_metronic/helpers'

interface Props {
  onClick: () => void
  title: string
  disabled?: boolean
  loading?: boolean
  icon?: string
  fullWidth?: boolean
  pulse?: boolean
  withoutAnimation?: boolean
}

const Button: FC<Props> = (
  {
    onClick,
    title,
    disabled,
    loading,
    icon,
    fullWidth,
    pulse,
    withoutAnimation
  }
) => (
  <Col className={ cn('col-auto align-self-end', {'w-100': fullWidth}) }>
    {loading && <Skeleton height={32} width={76} className='darkLoading' />}
    <div className={cn('d-flex align-items-center py-1', {'d-none': loading})}>
      <div
        onClick={() => !disabled && onClick()}
        className={cn(
          'position-relative d-flex align-items-center cursor-pointer btn btn-sm btn-bg-gray-300',
          {'no-pointer-events opacity-60': disabled, pulse, 'w-100 justify-content-center': fullWidth, 'btn-active-primary button-active' : !withoutAnimation}
        )}
      >
        {icon && <KTSVG path={'/media/icons/duotune' + icon} className='svg-icon-5 svg-icon-dark me-1' />}
        <p className='mb-0 text-white'>{title}</p>
        {pulse && <span className='pulse-ring' />}
      </div>
    </div>
  </Col>
)

export default Button
