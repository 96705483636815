import ChainOverview from 'app/pages/ChainOverview'
import React, {FC, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import Page from '_metronic/layout/Page'
import {FallbackView} from '_metronic/partials'
import DataApi from 'app/pages/DataApi'
import GuildList from 'app/pages/GuildList'
import GuildStats from 'app/pages/GuildStats'
import GuildValidation from 'app/pages/GuildValidation'
import Report from 'app/pages/Report'
import NodeFinder from 'app/pages/NodeFinder'
import NetworkInfo from 'app/pages/NetworkInfo'
import NodeTester from 'app/pages/NodeTester'
import {ContentType} from 'consts'
import {MasterInit} from '_metronic/layout/MasterInit'
import ErrorsPage from 'app/pages/Errors'

const Routes: FC = () => {
  return (
    <>
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path='/validations/:validationIdOrGuildName' exact>
            <Page component={<GuildValidation />} fullWidth />
          </Route>
          <Route path='/stats/guild/:guild' exact>
            <Page component={<GuildStats />} />
          </Route>
          <Route path='/stats/guild' exact>
            <Page component={<GuildList contentType={ContentType.Statistics} />} />
          </Route>
          <Route path='/validations' exact>
            <Page component={<GuildList contentType={ContentType.Validation} />} />
          </Route>
          <Route path='/reports/nodes/:nodeType'>
            <Page component={<Report />} />
          </Route>
          <Route path='/error' component={ErrorsPage} />
          <Route path='/overview'>
            <Page component={<ChainOverview />} />
          </Route>
          <Route path='/services/data-api'>
            <Page component={<DataApi />} />
          </Route>
          <Route path='/services/node-finder' exact>
            <Page component={<NodeFinder />} />
          </Route>
          <Route path='/services/network-info' exact>
            <Page component={<NetworkInfo />} />
          </Route>
          <Route path='/services/node-tester' exact>
            <Page component={<NodeTester />} />
          </Route>
          <Redirect from='/auth' to='/dashboard' />
          <Redirect exact from='/' to='/overview' />
          <Redirect to='error/404' />
        </Switch>
      </Suspense>
      <MasterInit />
    </>
  )
}

export {Routes}
