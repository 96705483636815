import moment from 'moment'
import {defaultDateContent} from 'consts'

/**
 * Returns a date object if the query passes validation (e.g. ?start=YYYY-MM-DD&end=YYYY-MM-DD, startDate < endDate) or null if validation failed
 * @param {string} query (e.g. ?start=2022-02-02&end=2022-02-09)
 * @return {DateItem | null }
 */
export const getDateValue = (query: string): DateItem | null => {
  const queryParams = new URLSearchParams(query)
  const dateRangeParams = queryParams.get('daterange')
  const currentDateValue = dateRangeParams && defaultDateContent.find((i) => i.queryParams === dateRangeParams)
  if (currentDateValue) {
    return currentDateValue
  }
  if (dateRangeParams && !currentDateValue) {
    return null
  }

  const startDate = queryParams.get('start')
  const endDate = queryParams.get('end')

  const getDateRange = (range: string[]) => {
    const startDate = moment(range[1], 'YYYY-MM-DD')
    const endDate = moment(range[0], 'YYYY-MM-DD')
    if (!startDate.isValid() && !endDate.isValid()) {
      return null
    }
    if (startDate.isAfter(moment().format('YYYY-MM-DD')) || endDate.isAfter(startDate)) {
      return null
    }
    return {
      key: 'selection',
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
    }
  }

  const currentDateRange = startDate && endDate && getDateRange([startDate, endDate])
  if (currentDateRange) {
    return {
      label: 'calendar',
      parameter: [
        moment(currentDateRange.startDate).format('YYYY-MM-DD'),
        moment(currentDateRange.endDate).format('YYYY-MM-DD'),
      ],
    }
  }

  return null
}
