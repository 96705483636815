import {KTSVG} from '_metronic/helpers'
import cn from 'classnames'
import React, {FC} from 'react'
import './style.scss'

interface Props {
  isMobile?: boolean
  handleClose: () =>  void
  handleClick: () => void
  show: boolean
}

const Notification: FC<Props> = ({isMobile, show, handleClose, handleClick}) => {

  return (
    <div
      style={{background: "#1e1e2d"}}
      className={cn('notification', {'alert alert-dismissible p-3': !isMobile})}>
      <div className='d-flex mb-1 justify-content-between align-items-center'>
        <p className={cn('light mb-0 fs-6', {'fs-5 fw-bold': isMobile})}>New Validation available</p>
        <div
          className='cursor-pointer'
          onClick={handleClose}>
          <KTSVG path={ '/media/icons/duotune/general/gen057.svg' }
                 className='svg-icon svg-icon-1 svg-icon-white' />
        </div>
      </div>
      <p className='mb-0 fs-7'>You are looking at an outdated validation. Check the latest validation <span
        className='fw-boldest cursor-pointer'
        onClick={ () => show && handleClick() }>here</span></p>
    </div>
  )
}

export default Notification
