/**
 * Returns a modified parameter string if the query passes validation or null if validation failed
 * @param {string} query (e.g. ?start=2022-02-02&end=2022-02-09&top21=t&seed=[32;100]&api=[46;100])
 * @param {boolean | undefined} isMatch
 * @return {string | null }
 */
export function getUrlParams (query: string, isMatch?: boolean): string | null {
  let modifyParams = ''
  const modifyQueries = query.split('&')
  if (modifyQueries.length) {
    modifyParams = isMatch ?
        modifyQueries.filter(i => i.match(/daterange|start|end|type/)).join('&')
      :
        modifyQueries.filter(i => !i.match(/daterange|start|end/)).join('&')
    if (modifyParams) {
      if (modifyParams.match(/[?]/)) {
        return modifyParams
      } else {
        return '?' + modifyParams
      }
    }
  }
  return null
}
