import cn from 'classnames'
import moment from 'moment'
import React, {FC, useState, useEffect} from 'react'
import Skeleton from 'react-loading-skeleton'
import ReactSyntaxHighlighter from 'react-syntax-highlighter'
import './style.scss'

interface Props {
  data: string[]
  fileName?: string
  header?: string
  title?: string
  loading?: boolean
  config?: boolean
  language?: string
  style?: {
    [name: string]: {
      [color: string]: string
    }
  }
  minLineNumberWidth?: boolean
  hideCopy?: boolean
  isTerminal?: boolean
}

const dots = [
  {color: 'danger', type: 'close'},
  {color: 'warning', type: 'collapse'},
  {color: 'success', type: 'disclose'}
]

const SyntaxHighlighter: FC<Props> = ({data, title, header, loading, config, fileName, language, style, minLineNumberWidth, hideCopy, isTerminal}) => {
  const [copied, setCopied] = useState(false)
  const [checked, setChecked] = useState(true)
  const [content, setContent] = useState('')
  const [downloadLink, setDownloadLink] = useState('')

  useEffect(() => {
    if (config) {
      setChecked(true)
      setContent([`#### LIST CREATED ON ${moment.utc().format()} UTC by Validationcore - Blacklusion #### `, ...data].map((i, n) => n > 0 ? 'p2p-peer-address = ' + i : i).join('\n'))
    } else {
      setContent(data.join('\n'))
    }
  }, [data])

  const handleCopy = () => {
    navigator.clipboard.writeText(content)
    setCopied(true)
  }

  const handleChecked = () => {
    if (!checked) {
      setContent([`#### LIST CREATED ON ${moment().format('YYYY-MM-DD HH:mm')} by Validationcore - Blacklusion #### `, ...data].map((i, n) => n > 0 ? 'p2p-peer-address = ' + i : i).join('\n'))
    } else {
      setContent(data.join('\n'))
    }
    setChecked(!checked)
  }

  const makeTextFile = () => {
    const d = new Blob([content], {type: 'text/plain'})
    if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink)
    setDownloadLink(window.URL.createObjectURL(d))
  }

  useEffect(() => {
    makeTextFile()
  }, [content])

  return (
    <div className={cn('reactSyntaxHighlighter', {'terminal': isTerminal})} onMouseEnter={() => setCopied(false)}>
      {isTerminal &&
        <div className='dots d-flex'>
          {dots.map(i =>
            <div key={i.type} className={cn('dot bg-' + i.color)}/>
          )}
        </div>
      }
      <div className='highlighterHeader'>
        <div className='d-flex align-items-center'>
          {header && <h3 className='mb-0 fs-3'>{header}</h3>}
          {config &&
          <div className='config form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input mr-2'
              checked={checked}
              type='checkbox'
              name='config'
              onChange={handleChecked}
            />
            <span className='text-muted fw-bolder form-check-label'>config.ini</span>
          </div>
          }
        </div>
        {title &&
          <p className='text-muted mt-1 fw-bolder fs-7 mb-0'>{title}</p>
        }
        {!loading && (
          <div className='buttons'>
            {fileName &&
              <div className='raw cursor-pointer button-active'>
                <a href={downloadLink} target='_blank' download={fileName}>
                  <p className='m-0 text-white'>Download</p>
                </a>
              </div>
            }
            {!hideCopy &&
              <div className='copy cursor-pointer button-active' onClick={handleCopy}>
                <p className='m-0'>{copied ? 'Copied' : 'Copy'}</p>
              </div>
            }
          </div>
        )}
      </div>
      {loading ? (
        <Skeleton height={300} width='100%' />
      ) : (
        <ReactSyntaxHighlighter className={cn('highlighter', {'textWhite': !style || !checked, minLineNumberWidth}) } style={checked && style || {}} language={config && checked ? 'ini' : language || 'javascript'} useInlineStyles showLineNumbers wrapLines>
          {content}
        </ReactSyntaxHighlighter>
      )}
    </div>
  )
}

export default SyntaxHighlighter
