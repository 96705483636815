import {gql} from '@apollo/client'

export const GET_GUILDS = gql`
  query($startDate: Date = null, $endDate: Date = null) {
      getGuilds(startDate: $startDate, endDate: $endDate) {
          name
          location_alpha
          rank
          url_logo_256
          validation_levels {
              organization
              node_seed
              node_api
              node_wallet
              node_history
              node_hyperion
              node_hyperion_partial
              node_atomic
          }
          stats_availability {
              node_seed
              node_api
              node_wallet
              node_history
              node_hyperion
              node_hyperion_partial
              node_atomic
          }
      }
  }
`
