import {gql} from '@apollo/client'

export const VALIDATION_BY_GUILD = gql`
  query ($guild: String!, $date: Date = null) {
    getValidationByGuild(guild: $guild, date: $date) {
      id
      guild
      validation_date
      guild_logo_256_url
      all_checks_ok
      validations {
        __typename
        ... on ValidationObject {
          validation_name
          status
          header
          message
          details
        }
        ... on RequestObject {
          validation_name
          status
          header
          message
          details
          url
          payload
          method
          request_timeout_ms
          request_ms
          http_code
          error_type
          error_message
        }
      }
      nodes {
        node_type
        nodes {
          id
          node_type
          endpoint_url
          is_ssl
          all_checks_ok
          partial_checks_ok
          location_longitude
          location_latitude
          validations {
            __typename
            ... on ValidationObject {
              validation_name
              status
              header
              message
              details
            }
            ... on RequestObject {
              validation_name
              status
              header
              message
              details
              url
              payload
              method
              request_timeout_ms
              request_ms
              http_code
              error_type
              error_message
            }
          }
        }
      }
    }
  }
`
