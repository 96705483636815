import cn from 'classnames'
import React, {FC} from 'react'
import {useHistory} from 'react-router-dom'
import {RangeSlider} from '../../../../../components'
import _ from 'lodash'
import './style.scss'

interface Props {
  value: number[]
  onChangeRangeValue: (value: number[]) => void
  onChangeNodeType: (value: string) => void
  nodeType: string
  nodeTypes: string[]
  marker?: boolean
}

const InfoBoard: FC<Props> = ({value, onChangeRangeValue, onChangeNodeType, nodeType, nodeTypes, marker}) => {
  const history = useHistory()

  const handleChange = (type: string) => {
    history.push(`?type=${type.split('_')[1].toLowerCase()}`)
    onChangeNodeType(type)
  }

  return (
    <div className='infoBoard px-7 py-5 menu-sub-dropdown'>
      <div className='justify-content-center d-flex'>
        <div className='mb-4 w-75'>
          <select
            className='form-select form-select-solid btn-label'
            aria-label='Select node'
            value={nodeType}
            onChange={({target}) => handleChange(target.value)}
          >
            {nodeTypes.map((i) => (
              <option key={i} value={i}>
                {_.startCase(i.split('_')[1].toLowerCase())} Endpoints
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={cn('range', {hide: !marker})}>
        <p className='mb-2 form-label fw-bold'>Radius</p>
        <RangeSlider
          secondary
          values={value}
          step={200}
          minValue={100}
          maxValue={50000}
          onChange={onChangeRangeValue}
          unit='km'
          largeThumb
        />
      </div>
    </div>
  )
}

export default InfoBoard
