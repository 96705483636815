import {useQuery} from '@apollo/client'
import cn from 'classnames'
import {getCurrentChainId} from 'config/chains'
import {GET_ENDPOINTS} from 'queries/getEndpoints'
import React, {FC, useEffect, useRef, useState} from 'react'
import {PageData} from '_metronic/layout/core'
import {Toolbar, Button, SyntaxHighlighter} from 'components'
import './style.scss'

const defaultPlaceholder = 'e.g. peer1.blacklusion.io:9876'

const NodeTester: FC = () => {
  const [value, setValue] = useState('')
  const [data, setData] = useState<string[]>([])
  const sseRef = useRef<EventSource | null>(null)
  const timerRef = useRef<any>(null)
  const [showHighlighter, setShowHighlighter] = useState(false)
  const link = `https://pub1.seedrunner.validationcore.io/validate-seed-live?chain_id=${getCurrentChainId()}&endpoint_url=`
  const [placeholder, setPlaceholder] = useState(defaultPlaceholder)
  const [endpoints, setEndpoints] = useState<Endpoint[]>([])

  const {data: endpointsData} = useQuery<QueryEndpointsRes>(GET_ENDPOINTS, {
    variables: {nodeType: 'NODE_SEED', getOnlyWorking: false, timeOffsetInMin: 60, queryMethod: 'ATLEAST_ONE_SUCCESSFUL_VALIDATION' },
  })

  const changePlaceholder = () => {
    clearInterval(timerRef.current)
    let count = 0
    timerRef.current = setInterval(() => {
      setPlaceholder('e.g. ' + endpoints[count].endpoint_url)
      if (count === endpoints.length - 1) {
        count = 0
      } else {
        count++
      }
    }, 2000)
  }

  const handleSubmit = () => {
    sseRef.current?.close()
    if (value.length) {
      setData([])
      clearInterval(timerRef.current)
      setTimeout(() => {
        setData(['Connected to remote seed runner'])
        let isData = false
        const sse = new EventSource(link + value)
        sseRef.current = sse
        const getRealtimeData = (value: string) => {
          const newValue = isJsonString(value) ? JSON.stringify(JSON.parse(value), null, '\t') : value
          setData(current => [...current, newValue])
          isData = true
          setTimeout(() => {
            const element = document.getElementsByClassName('highlighter')[0]
            element.scrollTo(0, element.scrollHeight)
          }, 1)
        }
        sse.onmessage = e => {
          getRealtimeData(JSON.parse(e.data).message)
        }
        sse.onerror = () => {
          sse.close()
          setPlaceholder(defaultPlaceholder)
          if (!isData) {
            setData(['===== Invalid url address ====='])
          }
        }
      }, showHighlighter ? 1 : 700)
      setShowHighlighter(true)
    } else {
      changePlaceholder()
      setShowHighlighter(false)
    }
  }

  const isJsonString = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (endpointsData?.getEndpoints) {
      const currentEndpoints = [...endpointsData.getEndpoints.filter(i => i.guild === 'blacklusionx'), ...endpointsData.getEndpoints.filter(i => i.guild !== 'blacklusionx').sort(() => Math.random() - .5)]
      setEndpoints(currentEndpoints)
    }
  }, [endpointsData])

  useEffect(() => {
    endpoints.length && changePlaceholder()
  }, [endpoints])

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current)
      sseRef.current?.close()
    }
  }, [])

  return (
    <>
      <PageData pageTitle='Node Tester'>Node Tester</PageData>
      <Toolbar />
      <div className={cn('nodeTester', {'defaultPosition': showHighlighter}) }>
        <div className='card py-8 pb-3 h-100'>
          <div className='firstSection'>
            <div className='headerSection'>
              <h1 className='mb-0 text-center mb-3'>Node Tester</h1>
              <p className='fs-6 text-gray-700 mb-6'>The Node Tester allows you to validate seednodes directly from your browser. It is not required for the node to be listed in the bp.json, just enter a valid endpoint URL and start the validation. The validation is carried out from one of our dedicated servers located in a data center to ensure a stable & fast internet simulating a production environment.</p>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <input type='text' placeholder={ placeholder } onKeyPress={ e => e.code === 'Enter' && handleSubmit() }
                     className='form-control text-white' value={ value }
                     onChange={ ({target}) => setValue(target.value) } />
              <div className='goButton'>
                <Button title='GO' onClick={ handleSubmit } fullWidth />
              </div>
            </div>
          </div>
          <div className='highlighterWrapper'>
            <SyntaxHighlighter data={ data } minLineNumberWidth hideCopy isTerminal />
          </div>
        </div>
      </div>
    </>
  )
}

export default NodeTester
