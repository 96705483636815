import React, {FC} from 'react'
import {PageData} from '_metronic/layout/core'
import DeveloperImage from 'assets/icons/developer.png'
import {Toolbar} from 'components'
import {ChainItem, getChainItem} from 'config/chains'

const DataApi: FC = () => {
  return (
    <>
      <PageData link='/overview' pageTitle='Data Api'>Data Api</PageData>
      <Toolbar />
      <div className='card px-6 py-20 mb-9 text-center'>
        <div className='mt-9 h-200px h-sm-325px'>
          <img src={DeveloperImage} alt='Skateboarder' className='mw-100 h-200px h-sm-325px' />
        </div>
        <h1 className='fs-2x fw-bolder mb-0'>Explore our Validationcore Api</h1>
        <p className='text-gray-600 fs-5 fw-bold py-7'>
          Extract our historical data without running your own Validationcore instance by using our easy-to-use GraphQL
          schema.
          <br /> You can query statistics, the latest validation of a guild, a list of working endpoints, and more.
        </p>
        <div className='d-flex justify-content-center'>
          <a href={getChainItem(ChainItem.api)} target='_blank' rel='noreferrer'>
            <div className='btn btn-primary btn-lg button-active'>
              <p className='m-0'>Validationcore Api</p>
            </div>
          </a>
        </div>
      </div>
    </>
  )
}

export default DataApi
