import moment from 'moment'
import React, {FC, useEffect, useRef, useState} from 'react'
import {Col} from 'react-bootstrap-v5'
import {useCountUp} from 'react-countup'
import {ChartArea} from './components'
import './style.scss'

interface Props {
  data: AvailabilityValue[]
  domain?: {startDate: Date; endDate: Date} | undefined
  dateRange?: string[]
}

const StatsAvailability: FC<Props> = ({data, domain, dateRange}) => {
  const [averageAvailability, setAverageAvailability] = useState<number>(
    Math.round(data.reduce((a, b) => a + b.availability, 0) / data.length) || 0
  )
  const [filteredData, setFilteredData] = useState<AvailabilityValue[]>(data)
  const countUpRef = useRef(null)
  const {start} = useCountUp({
    ref: countUpRef,
    duration: 1.3,
    end: averageAvailability,
    suffix: '%'
  })

  useEffect(() => {
    if (dateRange?.length) {
      const startDate = moment.utc(dateRange[0])
      const endDate = moment.utc(dateRange[1])

      let filteredAvailabilityValues = data.filter((i) => {
        const date = moment.utc(i.date).format('YYYY-MM-DD')
        return startDate >= moment.utc(date) && moment.utc(date) >= endDate
      })

      const diff = startDate.diff(endDate, 'days')

      if (diff !== filteredAvailabilityValues.length + 1) {
        for (let i = 0; i <= diff; i++) {
          const day = moment.utc(endDate).add(i, 'day').format('YYYY-MM-DD')
          const foundDay = filteredAvailabilityValues.find(({date}) => moment.utc(date).format('YYYY-MM-DD') === day)
          if (!foundDay) {
            filteredAvailabilityValues.push({date: day, availability: 0})
          }
        }
      }

      filteredAvailabilityValues = filteredAvailabilityValues.sort((a, b) => {
        if (moment.utc(a.date) >= moment.utc(b.date)) {
          return 1
        } else {
          return -1
        }
      })
      setFilteredData(filteredAvailabilityValues)
      setAverageAvailability(
        Math.round(
          filteredAvailabilityValues.reduce((a, b) => a + b.availability, 0) / filteredAvailabilityValues.length
        ) || 0
      )
    } else {
      setAverageAvailability(Math.round(data.reduce((a, b) => a + b.availability, 0) / data.length) || 0)
      setFilteredData(data)
    }
  }, [dateRange])

  const uptimeValueGetDate = (d: AvailabilityValue): Date => new Date(d.date)

  useEffect(() => {
    if (domain) {
      const dataCopy = data.filter((s) => {
        const x = uptimeValueGetDate(s)
        if (!domain) return true
        return x >= domain.startDate && x <= domain.endDate
      })
      const sum = dataCopy.reduce((a, b) => a + b.availability, 0)
      setAverageAvailability(Math.round(sum / dataCopy.length) || 0)
      setFilteredData(dataCopy)
    }
  }, [domain])

  useEffect(() => {
    start()
  }, [averageAvailability, data])

  return (
    <div className='statsAvailability mt-6'>
      <div className='d-flex justify-content-between mb-5 px-9'>
        <div className='mr-2'>
          <h2 className='mb-0 title text-gray-800'>Availability</h2>
        </div>
        <Col className='col-auto'>
          {filteredData.length ?
              <h1 className='text-center percent mb-0' ref={countUpRef}/>
            :
              <h1 className='text-center percent mb-0'>--%</h1>
          }
          <p className='text-gray-700 mb-0'>ø average</p>
        </Col>
      </div>
      <div className='chart'>
        <ChartArea data={filteredData} />
      </div>
    </div>
  )
}

export default StatsAvailability
