import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '_metronic/helpers'
import {useQuery} from '@apollo/client'
import {GET_ENDPOINTS} from 'queries/getEndpoints'
import Skeleton from 'react-loading-skeleton'
import {NodeType} from 'consts'

type Props = {
  image: string
  title: string
  nodeType: NodeType
  link: string
  status: 'up' | 'down'
  statusValue: number
  statusDesc: string
}

const EndpointTile: FC<Props> = ({image, title, nodeType, link}) => {
  let reqData: string[] = []

  const {data, loading, error} = useQuery<QueryEndpointsRes>(GET_ENDPOINTS, {
    variables: {nodeType: nodeType, getOnlyWorking: true},
  })
  if (data) {
    if (nodeType !== NodeType.NODE_SEED) {
      const tmpArray: string[] = []
      data.getEndpoints.forEach(({endpoint_url}) => {
        const hostname = new URL(endpoint_url).hostname
        if (!tmpArray.includes(hostname)) tmpArray.push(hostname)
      })
      reqData = tmpArray
    } else {
      reqData = data.getEndpoints.map(({endpoint_url}) => endpoint_url)
    }
  }

  return (
    <Link to={link}>
      <div className='card h-100 card-hover'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <KTSVG path={image} className='svg-icon-3x me-5' />
            <p className='fs-4 fw-bold text-gray-600 m-0'>{title}</p>
          </div>
        </div>
        <div className='card-body d-flex flex-column px-9 pt-6 pb-8'>
          <div className='fs-2tx fw-bolder text-white mb-3'>
            {loading ? (
              <Skeleton width={60} />
            ) : error ? (
              <i className='las la-unlink fs-2tx fw-bolder mb-3' />
            ) : (
              reqData.length
            )}
            &nbsp;
          </div>
        </div>
      </div>
    </Link>
  )
}

export default EndpointTile
