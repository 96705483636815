import React, {FC} from 'react'
import {OverlayTrigger, Table, Tooltip} from 'react-bootstrap-v5'
import {ContentType} from 'consts'
import cn from 'classnames'
import {ContentRow} from './components'
import getNodeLabel from 'helpers/getNodeLabel'

import './styles.scss'
import StatsAvailability from '../StatsAvailability'

interface Props {
  contentType: ContentType
  validationObject: NodeValidation | Validation
  dateRange?: string[]
}

const ContentTable: FC<Props> = ({contentType, validationObject, dateRange}) => {
  const isPartial = ('partial_checks_ok' in validationObject && validationObject.partial_checks_ok == 4) && !((validationObject.all_checks_ok ?? 0) == 4)

  return (
    <div className={cn('contentTable card', {'py-8 active': contentType === ContentType.Statistics})}>
      <div className={cn('px-9', {'headerSticky py-7': contentType !== ContentType.Statistics})}>
        <h1 className='mb-0 d-flex align-items-center'>
          {validationObject.__typename === 'Validation'
            ? 'Organization'
            : getNodeLabel('node_type' in validationObject ? validationObject.node_type : '') + ' Node'}{' '}
          {'is_ssl' in validationObject && validationObject.is_ssl && (
            <span className='badge badge-light-dark ml-1 mb-0'>SSL</span>
          )}
          {'partial_checks_ok' in validationObject && validationObject.partial_checks_ok && (
                          <OverlayTrigger
                delay={{ show: 250, hide: 400 }}
                placement='top'
                overlay={(props) => <Tooltip id='tlp' {...props}><p className='mb-0'>
                  {isPartial ? "If you are planning to request data within the past 42days, this endpoint will work fine. It is missing data before." : "This Hyperion offers a full history!"}
                </p></Tooltip>}
              >
                            {isPartial ?
              <span className='badge badge-info ml-1 mb-0 d-inline-flex align-middle'>Partial</span> :
              <span className='badge badge-success ml-1 mb-0'>Full</span>}
              </OverlayTrigger>
          )}
        </h1>
        {'endpoint_url' in validationObject && <p className='mb-0 text-gray-700'>{validationObject.endpoint_url}</p>}
      </div>
      {contentType === ContentType.Validation ? (
        <Table hover>
          <tbody>
            {validationObject.validations?.map((i, num) => (
              <ContentRow key={i.validation_name + num} validationObject={i} showOnlyErrors={false} />
            ))}
          </tbody>
        </Table>
      ) : (
        'statistics' in validationObject &&
        validationObject.statistics?.availability && (
          <StatsAvailability dateRange={dateRange} data={validationObject.statistics.availability} />
        )
      )}
    </div>
  )
}

export default ContentTable
