import _ from 'lodash'
import moment from 'moment'
import {ALL_VALIDATION_BY_IDS} from 'queries/getAllValidationIds'
import {VALIDATION_DATE_RANGE} from 'queries/getValidationDateRange'
import React, {FC, useEffect, useRef, useState} from 'react'
import {PageData} from '_metronic/layout/core'
import {ContentType} from 'consts'
import {Col, Row} from 'react-bootstrap-v5'
import {useParams} from 'react-router'
import {useLazyQuery, ApolloError} from '@apollo/client'
import Skeleton from 'react-loading-skeleton'
import {NoDataAvailable, ContentTable, Toolbar, Button, Map} from 'components'
import {useHistory, useLocation} from 'react-router-dom'
import {SideScrollspyNav, TimelineSlider, Notification} from './components'
import getNodeLabel from 'helpers/getNodeLabel'
import useMediaQuery from 'hooks/useMediaQuery'
import {useMediaQuery as rrUseMediaQuery} from 'react-responsive'
import {VALIDATION_BY_GUILD} from 'queries/getValidationByGuild'
import {VALIDATION_BY_ID} from 'queries/getValidationById'
import './style.scss'
import useOnClickOutside from 'hooks/useOnClickOutside'
import {KTSVG} from '_metronic/helpers'
import { usePageVisibility } from 'react-page-visibility'
import cn from 'classnames'

const defaultTimelineConfig = [moment(moment().subtract(1, 'year').format('YYYY-MM-DD')).unix(), moment(moment().format('YYYY-MM-DD')).unix()]
const step = 86400

const GuildValidation: FC = () => {
  const props = useParams<{validationIdOrGuildName: string}>()
  const ref = useRef<HTMLDivElement>(null)
  const sidebarRef = useRef(null)
  const mapRef = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const {search, pathname} = useLocation()

  const isMobileScreen = rrUseMediaQuery({query: '(max-width: 767px)'})
  const isSmallScreen = rrUseMediaQuery({query: '(max-width: 992px)'})
  const isSidebar = useMediaQuery(ref, 800)

  const [showSidebar, setShowSidebar] = useState(false)
  const showButton = isSidebar && !isSmallScreen ? false : isSidebar && isSmallScreen ? true : !isSidebar
  const [date, setDate] = useState<string>('')
  const [timelineConfig, setTimelineConfig] = useState<number[]>(defaultTimelineConfig)
  const isVisible = usePageVisibility()

  const [data, setData] = useState<Validation | null>(null)
  const [nodes, setNodes] = useState<NodeValidation[]>([])
  const [loading, setLoading] = useState(true)
  const [refetchLoading, setRefetchLoading] = useState(false)
  const [timelineLoading, setTimelineLoading] = useState(true)
  const [error, setError] = useState<ApolloError | undefined>()
  const [title, setTitle] = useState<string>('')
  const [markers, setMarkers] = useState<Marker[]>([])
  const [navData, setNavData] = useState<SideNavItem[]>([])
  const contentType = ContentType.Validation
  const [validationTime, setValidationTime] = useState<Partial<Validation>[]>([])
  const [guildName, setGuildName] = useState<string>('')
  const [validationTimeLoading, setValidationTimeLoading] = useState(false)
  const [latestValidation, setLatestValidation] = useState(true)
  const [showNotification, setShowNotification] = useState(false)
  const timeoutRef = useRef<any>(null)

  const [getValidationByGuild, {refetch, called, data: currentData, previousData, startPolling, stopPolling}] = useLazyQuery<QueryValidationByGuildRes>(VALIDATION_BY_GUILD)

  const [getAllValidationIds, {refetch: refetchAllValidationIds, called: calledAllValidationIds}] = useLazyQuery<QueryAllValidationIds>(ALL_VALIDATION_BY_IDS)

  const [getValidationDateRange, {called: calledValidationDateRange}] = useLazyQuery<QueryValidationDateRange>(VALIDATION_DATE_RANGE)

  const [getValidationById, {refetch: refetchValidationById, called: calledValidationById}] = useLazyQuery<QueryValidationByIdRes>(VALIDATION_BY_ID)

  const getDateRange = (guild: string, date: string | null) => {
    getValidationDateRange({variables: {guild}}).then(res => {
      setTimelineLoading(res.loading)
      if (res.data) {
        const currentDate = date || moment(res.data.getValidationDateRange.max).utc().format('YYYY-MM-DD')
        setDate(currentDate)
        getValidationTime(currentDate, guild)
        // Adding 1 to max date to prevent crash if min = max
        setTimelineConfig([moment(moment(res.data.getValidationDateRange.min).utc().format('YYYY-MM-DD')).unix(), moment(moment(res.data.getValidationDateRange.max).utc().format('YYYY-MM-DD')).unix() + 1])
      }
    })
  }

  const getValidationTime = (date: string, guildName: string) => {
    setValidationTimeLoading(true)
    if (!calledAllValidationIds) {
      getAllValidationIds({variables: {guild: guildName, date}}).then(res => {
        if (res.data) {
          setValidationTime(res.data.getAllValidationIds)
          setValidationTimeLoading(false)
        }
      })
    } else {
      refetchAllValidationIds({guild: guildName, date}).then(res => {
        if (res.data) {
          setValidationTime(res.data.getAllValidationIds)
          setValidationTimeLoading(false)
        }
      })
    }
  }

  const getGuild = (date: string | null) => {
    stopPolling()
    const guild = props.validationIdOrGuildName
    if (guild.length <= 12) {
      getValidationTime(date || moment().format('YYYY-MM-DD'), guild)
      setGuildName(guild)
      if (!called) {
        !calledValidationDateRange && getDateRange(guild, date)
        getValidationByGuild({variables: {guild, date}}).then((res) => {
          setLoading(res.loading)
          setError(res.error)
          if (res.data) {
            const nodes = res.data?.getValidationByGuild.nodes?.map(i => ({...i, nodes: _.sortBy(i.nodes, [(node => node.endpoint_url.replace(/(http|https):\/\//, '')), (node => node.is_ssl)])}))
            setData({...res.data.getValidationByGuild, nodes})
            setTitle(res.data.getValidationByGuild.guild)
            if (!date && !latestValidation && !showNotification) startPolling(30000)
          }
        })
      } else {
        setRefetchLoading(true)
        refetch({guild, date}).then((res) => {
          setLoading(res.loading)
          setError(res.error)
          setRefetchLoading(false)
          if (res.data) {
            const nodes = res.data?.getValidationByGuild.nodes?.map(i => ({...i, nodes: _.sortBy(i.nodes, [(node => node.endpoint_url.replace(/(http|https):\/\//, '')), (node => node.is_ssl)])}))
            setData({...res.data.getValidationByGuild, nodes})
            setTitle(res.data.getValidationByGuild.guild)
            if (!date && !latestValidation && !showNotification) startPolling(30000)
          }
        })
      }
    } else {
      if (!calledValidationById) {
        getValidationById({variables: {id: props.validationIdOrGuildName}}).then((res) => {
          setLoading(res.loading)
          setError(res.error)
          if (res.data) {
            const nodes = res.data?.getValidationById.nodes?.map(i => ({...i, nodes: _.sortBy(i.nodes, [(node => node.endpoint_url.replace(/(http|https):\/\//, '')), (node => node.is_ssl)])}))
            setData({...res.data.getValidationById, nodes})
            setTitle(res.data.getValidationById.guild)
            !calledValidationDateRange && getDateRange(res.data.getValidationById.guild, moment(res.data.getValidationById.validation_date).format('YYYY-MM-DD'))
            setGuildName(res.data.getValidationById.guild)
          }
        })
      } else {
        setRefetchLoading(true)
        refetchValidationById({id: props.validationIdOrGuildName}).then(res => {
          setLoading(res.loading)
          setError(res.error)
          setRefetchLoading(false)
          if (res.data) {
            const nodes = res.data?.getValidationById.nodes?.map(i => ({...i, nodes: _.sortBy(i.nodes, [(node => node.endpoint_url.replace(/(http|https):\/\//, '')), (node => node.is_ssl)])}))
            setData({...res.data.getValidationById, nodes})
            setTitle(res.data.getValidationById.guild)
          }
        })
      }
    }
  }

  useEffect(() => {
    if (data?.nodes) {
      const currentNavData: SideNavItem[] = [{
        label: 'Organization',
        status: data.all_checks_ok
      }]
      const currentNodes: NodeValidation[] = []
      const currentMarkers: Marker[] = []
      data.nodes.forEach(({nodes}) => {
        if (nodes?.length) {
          nodes.some(i => {
            if (i.location_latitude && i.location_longitude) {
              currentMarkers.push({
                url: i.endpoint_url,
                isWorking: i.all_checks_ok === 4,
                coordinates: [i.location_longitude, i.location_latitude]
              })
            }
          })
          nodes.forEach(i => {
            currentNavData.push({
              label: getNodeLabel(i.node_type),
              status: (i.node_type === "NodeHyperion" ? (i.all_checks_ok === 4 ? i.all_checks_ok : i.partial_checks_ok) : i.all_checks_ok),
              isSsl: i.is_ssl,
              url: i.endpoint_url,
              id: i.id
            })
          })
          currentNodes.push(...nodes)
        }
      })
      const shortIds = getShortIds(currentNodes.map(i => i.id || ''))
      setNodes(currentNodes.map((i, num) => {
        return {...i, id: shortIds[num]}
      }))
      setMarkers(currentMarkers)
      setNavData(currentNavData.map((i, num) => {
        if (i.id) {
          return {...i, id: shortIds[num - 1]}
        } else {
          return i
        }
      }))
    }
  }, [data])

  const getShortIds = (arr: string[], num = 1): string[] => {
    const currentIds = arr.map(i => i.split('-').splice(0, num).join('-'))
    if (_.uniq(currentIds).length === arr.length) {
      return currentIds
    } else {
      return getShortIds(arr, num + 1)
    }
  }

  useOnClickOutside(sidebarRef, () => setShowSidebar(false))

  const handleClick = () => {
    if (mapRef.current) {
      const y = mapRef.current.offsetTop
      window.scrollTo({top: y - 145, behavior: 'smooth'})
    }
  }

  useEffect(() => {
    if (search) {
      const queryParams = new URLSearchParams(search)
      const currentDate = moment(queryParams.get('date')).format('YYYY-MM-DD')
      if (currentDate !== 'Invalid date') {
        setDate(currentDate)
        getGuild(currentDate === moment().format('YYYY-MM-DD') ? null : currentDate)
      } else {
        getGuild(null)
        history.replace({search: ''})
      }
    } else {
      getGuild(null)
    }
  }, [pathname, search])

  useEffect(() => {
    if (date !== '') {
      if (date === moment().utc().format('YYYY-MM-DD')) {
        timeoutRef.current && clearTimeout(timeoutRef.current)
        if (previousData && currentData && moment(previousData.getValidationByGuild.validation_date).format('YYYY-MM-DD') === moment(currentData.getValidationByGuild.validation_date).format('YYYY-MM-DD')) {
          setLatestValidation(false)
          setShowNotification(true)
          stopPolling()
        } else {
          setLatestValidation(true)
          setShowNotification(false)
        }
      } else {
        timeoutRef.current && clearTimeout(timeoutRef.current)
        setLatestValidation(false)
        setShowNotification(true)
        stopPolling()
      }
    }
  }, [previousData, date])

  useEffect(() => {
    if (isVisible && date === moment().utc().format('YYYY-MM-DD')) {
      if (!showNotification) {
        startPolling(1)
        setTimeout(() => {
          startPolling(30000)
        }, 100)
      } else {
        stopPolling()
      }
    } else {
      stopPolling()
    }
  }, [isVisible])

  useEffect(() => {
    return () => {
      stopPolling()
      timeoutRef.current && clearTimeout(timeoutRef.current)
    }
  }, [])

  const getLatestValidation = () => {
    const guild = props.validationIdOrGuildName
    setDate(moment().utc().format('YYYY-MM-DD'))
    setLatestValidation(true)
    setShowNotification(false)
    setLoading(true)
    if (guild.length > 12) {
      history.replace(guildName)
    }
    if (search) {
      return history.replace({search: ''})
    }
    getGuild(null)
  }

  // Closing the notification will hide it for X minutes (by default 5mins)
  const handleCloseNotification = () => {
    setShowNotification(false)
    if (date !== moment().utc().format('YYYY-MM-DD')) {
      // @ts-ignore
      const delay = (window.env.NOTIFICATION_TIMEOUT || 5) * 60000
      timeoutRef.current = setTimeout(() => {
        setShowNotification(true)
      }, delay)
    } else {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    }
  }

  return (
    <>
      <PageData
        link='/validations'
        loading={loading}
        pageTitle={
          _.startCase(title) + ' Validation'
        }
        icon={(!loading && !error && data?.guild_logo_256_url) || undefined}
        subtext={
          (!isSmallScreen &&
            !loading &&
            !error &&
            data &&
            `Validated at ${new Date(data.validation_date).toLocaleString()}`) ||
          undefined
        }
      >
        {title}
      </PageData>
      <Toolbar
        rightChildren={
          !isMobileScreen &&
          <Button
            title='Show Statistics'
            loading={loading}
            onClick={() => history.push('/stats/guild/' + props.validationIdOrGuildName)}
            disabled={loading || !!error}
          />
        }
      />
      <div className='guildValidation w-100' ref={ref}>
        {/* begin::Container */}
        {!loading && error ? (
          <NoDataAvailable />
        ) : (
          <Row className='m-0 w-100 mb-5 mb-xxl-8'>
            {/* begin::Validation Table */}
            <Col className={cn('validationTable', {single: !isSidebar || isSmallScreen})}>
              {!!nodes.length && showButton &&
              <div className={cn('notificationWrapper', {'show': showNotification})}>
                <Notification isMobile handleClose={handleCloseNotification} handleClick={getLatestValidation} show={showNotification}/>
              </div>
              }
              {loading ? (
                <>
                  <Skeleton height={200} className='mb-3' />
                  <Skeleton height={400} count={5} className='mb-3' />
                </>
              ) : (
                <div>
                  <div className='mb-9'>
                    <TimelineSlider validationTimeLoading={validationTimeLoading} onChangeFinalData={date => getValidationTime(date, guildName)} guildName={guildName} validationTime={validationTime} refetchLoading={refetchLoading} timelineLoading={timelineLoading} items={navData} config={timelineConfig} currentValue={moment(date).unix()} step={step} onSubmit={setDate}/>
                  </div>
                  {data?.validations &&
                    <div id='organization'>
                      <ContentTable validationObject={data} contentType={contentType} />
                    </div>
                  }
                  {nodes.map((i, num) => {
                    const nodeLabel = getNodeLabel(i.node_type)
                    return (
                      <div className='mt-9' key={nodeLabel + '-' + num} id={`i${i.id}`}>
                        <ContentTable validationObject={i} contentType={contentType} />
                      </div>
                    )
                  })
                  }
                  <div className='mt-9' ref={mapRef}>
                    <Map markers={markers} loading={loading} />
                  </div>
                  {isSmallScreen && (
                    <p className='mt-5 text-gray-700'>
                      {'Validated at ' + (data && new Date(data.validation_date).toLocaleString())}
                    </p>
                  )}
                </div>
              )}
            </Col>
            {/* end::Validation Table */}
            <div className='rightContainer'>
              <div className='section'>
                {!!nodes.length && !showButton &&
                  <div className={cn('notificationWrapper', {'show': showNotification})}>
                    <Notification handleClose={handleCloseNotification} handleClick={getLatestValidation} show={showNotification}/>
                  </div>
                }
                {/* begin::Sidebar */}
                <div
                  ref={sidebarRef}
                  className={cn('sidebar', {'mobileSidebar': showButton, 'showSidebar': showSidebar && showButton, 'indent': showNotification})}
                >
                  <SideScrollspyNav data={navData} contentType={contentType} isMobile={showButton}/>
                  {!loading &&
                  <Button title='Map' icon='/maps/map006.svg' onClick={handleClick} fullWidth/>
                  }
                </div>
                {/* end::Sidebar */}
              </div>
            </div>
            {/* begin::Button */}
            {!loading && showButton && (
              <div className={cn('buttonShow', {hideButton: showSidebar, 'indent': showNotification})} onClick={() => setShowSidebar(true)}>
                <KTSVG path={'/media/icons/duotune/layouts/lay004.svg'} className='svg-icon-primary' />
              </div>
            )}
            {/* end::Button */}
          </Row>
        )}
        {/* end::Container */}
      </div>
    </>
  )
}

export default GuildValidation
