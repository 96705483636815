export enum ChainItem {
  name = 'name',
  logo = 'logo',
  api = 'api',
  url = 'url',
  blocks = 'blocks'
}

interface Chains {
  [id: string]: Chain
}

interface Chain {
  name: string
  logo: string
  api: string
  url: string
  chainId?: string
  blocks: string
}

const chains: Chains = {
  '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4': {
    name: 'WAX Mainnet',
    logo: 'wax_256.png',
    api: 'https://wax.validationcore.io/graphql',
    url: 'https://wax.validationcore.io',
    chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
    blocks: 'https://wax.bloks.io'
  },
  'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12': {
    name: 'WAX Testnet',
    logo: 'wax_256.png',
    api: 'https://wax-test.validationcore.io/graphql',
    url: 'https://wax-test.validationcore.io',
    chainId: 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12',
    blocks: 'https://wax-test.bloks.io'
  },
}

/**
 * Returns chain id from env
 * @return {string} Chain id
 */
export function getCurrentChainId(): string {
  // @ts-ignore
  return window.env.CHAIN_ID || ''
}

/**
 * Returns value of itemKey for the current chain (e.g. itemKey: "name" -> "WAX Mainnet")
 * @param {ChainItem} itemKey
 * @return {string} Chain value
 */
export function getChainItem(itemKey: ChainItem): string {
  return chains[getCurrentChainId() as string][itemKey]
}

/**
 * Returns array containing all chains
 * @return {Chain[]} Array of chains
 */
export function getAllChains(): Chain[] {
  const CHAIN_ID = getCurrentChainId()
  const newChains: Chain[] = []
  newChains.push({chainId: CHAIN_ID, ...chains[CHAIN_ID as string]})

  Object.entries(chains).forEach(([key, value]) => {
    key !== CHAIN_ID && newChains.push({chainId: CHAIN_ID, ...value})
  })

  return newChains
}

/**
 * Redirects to website for another chain
 * @param {string} chainId
 */
export function handleChainChange(chainId: string): void {
  window.location.href = chains[chainId].url
}
