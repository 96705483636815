import {KTSVG} from '_metronic/helpers'
import cn from 'classnames'
import {Button, DateCalendar, Hint} from 'components'
import useOnClickOutside from 'hooks/useOnClickOutside'
import moment from 'moment'
import React, {FC, useEffect, useRef, useState} from 'react'
import {Range} from 'react-range'
import './style.scss'
import {LineChart, ResponsiveContainer, XAxis} from 'recharts'

import Skeleton from 'react-loading-skeleton'
import {useHistory} from 'react-router-dom'
import {ValidationLevel} from '../../../../../consts'


interface Props {
  validationTime: Partial<Validation>[]
  guildName: string
  items: SideNavItem[]
  currentValue: number
  step: number
  onSubmit: (v: string, id?: string) => void
  timelineLoading: boolean
  config: number[]
  refetchLoading: boolean
  onChangeFinalData: (date: string) => void
  validationTimeLoading: boolean
}

interface DataItem {
  minValidationLevel: ValidationLevel
  label: string
  index: number
  icon: string
  isActive: boolean
  id?: string
}

const icons: {icon: string, label: string}[] = [
  {icon: 'organization.svg', label: 'Organization'},
  {icon: 'seed.svg', label: 'Seed'},
  {icon: 'api.svg', label: 'Api'},
  {icon: 'wallet.svg', label: 'Wallet'},
  {icon: 'history.svg', label: 'History'},
  {icon: 'hyperion.svg', label: 'Hyperion'},
  {icon: 'atomic.svg', label: 'Atomic'}
]

const TimelineSlider: FC<Props> = ({validationTime, validationTimeLoading, guildName, items, currentValue, step, config, onSubmit, timelineLoading, refetchLoading, onChangeFinalData}) => {
  const [value, setValue] = useState([currentValue])
  const [rangeValues, setRangeValues] = useState<number[]>([])
  const [show, setShow] = useState(false)
  const [data, setData] = useState<DataItem[]>([])
  const history = useHistory()
  const [isLatestValidation, setIsLatestValidation] = useState(currentValue === value[0])
  const [checked, setChecked] = useState(true)
  const [selectedTime, setSelectedTime] = useState('')
  const [showCalendar, setShowCalendar] = useState(false)
  const calendarRef = useRef(null)

  useOnClickOutside(calendarRef, () => setShowCalendar(false))

  useEffect(() => {
    const minValue = config[0]
    const maxValue = config[1]
    const values = [minValue]
    let value = minValue
    while (value < maxValue) {
      const currentValue = value + step
      value = currentValue
      values.push(currentValue)
    }
    setRangeValues(values)
  }, [config])

  useEffect(() => {
    setData(icons.map(NodeType => {
      let notWorking = -1;
      const combinedValidationLevel: ValidationLevel = items.reduce((prev, cur, index) => {
        const currentValidationLevel = cur.status ?? ValidationLevel.ERROR
        if (cur.label === NodeType.label && currentValidationLevel < ValidationLevel.SUCCESS && currentValidationLevel < prev) {
          notWorking = index;
          return currentValidationLevel
        }
        else {
          return prev
        }
      }, ValidationLevel.SUCCESS)
      return {
        minValidationLevel: combinedValidationLevel,
        label: NodeType.label,
        index: items.findIndex(el => el.label === NodeType.label),
        icon: NodeType.icon,
        isActive: items.some(el => el.label === NodeType.label),
        id: notWorking === -1 ? items.find(el => el.label === NodeType.label)?.id : items[notWorking].id
      }
    }))
  }, [items])

  const handleClickIcon = (id: string) => {
    const y = document.getElementById(id)?.offsetTop
    if (y) {
      window.scrollTo({top: y - 145, behavior: 'smooth'})
    }
  }

  const handleSubmit = (data?: Partial<Validation>) => {
    if (checked || (!checked && data?.id)) {
      const params = isLatestValidation ? moment().utc().format('YYYY-MM-DD') : moment.unix(value[0]).format('YYYY-MM-DD')
      if (checked) {
        setSelectedTime('')
        const query = `date=${params}`
        const path = isLatestValidation ? guildName : guildName + '?' + query
        history.replace(path)
      }
      if (data?.id) {
        history.replace(data.id)
      }
      onSubmit(params)
    }
  }

  useEffect(() => {
    setValue([currentValue])
  }, [currentValue])

  useEffect(() => {
    setIsLatestValidation((currentValue === value[0] || value[0] === config[1]) && checked && !selectedTime.length)
  }, [value, currentValue, checked, selectedTime])

  const handleChangeTime = (value: string) => {
    setSelectedTime(value)
    handleSubmit(validationTime.find(i => i.validation_date === value))
  }

  const handleChangeChecked = () => {
    setChecked(!checked)
  }

  const handleChangeValue = (v: number[]) => {
    setValue(v)
    setSelectedTime('')
  }

  const handleDateValueChange = (i: number) => {
    setValue([i])
    onChangeFinalData(moment.unix(i).format('YYYY-MM-DD'))
  }


  return (
    <div className="timelineSlider card">
      <div>
        <div className='px-9 py-7'>
          <h1 className='mb-0 d-flex align-items-center'>
            Overview
          </h1>
        </div>
        <div className='categories d-flex align-items-center flex-wrap justify-content-between px-9'>
          {data.length && data.map(i =>
            <div
              key={i.label}
              className={cn('d-flex cursor-pointer px-2 mb-3 col flex-column justify-content-center align-items-center', {'disable': !i.isActive})}
              onClick={() => handleClickIcon(i.id ? `i${i.id}` : i.label.toLowerCase())}
            >
              <div className='icon mb-2'>
                <KTSVG path={'/media/icons/blacklusion-duotone/' + i.icon} className={(i.minValidationLevel >= ValidationLevel.WARN && i.isActive) ? 'svg-icon-gray-800' : 'svg-icon-gray-200'}/>
                <div className='status'>
                  {i.isActive && getValidationIcon(i.minValidationLevel ?? ValidationLevel.ERROR)}
                </div>
              </div>
              <p className={cn('m-0', {'text-white': i.minValidationLevel, 'text-gray-400': !i.isActive, 'text-gray-700': !i.minValidationLevel && i.isActive})}>
                {i.label}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className='position-relative'>
        <div className='px-9 py-7'>
          <div className='d-flex align-items-center cursor-pointer' onClick={() => setShow(!show)}>
            <h2 className='mb-0 d-flex align-items-center mr-1 text-gray-700'>
              Time Machine
            </h2>
            <KTSVG path={'/media/icons/duotune/arrows/arr074.svg'} className={cn('svg-icon-muted svg-icon-2 fa-rotate-90 transition', {'fa-rotate-270': !show})} />
            <span className='badge badge-secondary ml-1 mb-0'>Try it out!</span>
          </div>
        </div>
        <div className={cn('slider', {'show': show})}>
          {timelineLoading ?
              <Skeleton height={105}/>
            :
              <>
                <div className='px-15 pb-7'>
                  <div className='position-relative'>
                     <Range
                      values={value}
                      step={step}
                      min={config[0]}
                      max={config[1]}
                      onFinalChange={(e) => onChangeFinalData(moment.unix(e[0]).format('YYYY-MM-DD'))}
                      onChange={handleChangeValue}
                      renderTrack={({props, children}) => (
                        <div
                          onMouseDown={props.onMouseDown}
                          onTouchStart={props.onTouchStart}
                          className='trackWrapper'
                          style={{...props.style}}
                        >
                          <div
                            ref={props.ref}
                            className='track'
                            style={{
                              background: 'transparent',
                            }}
                          >
                            {children}
                          </div>
                        </div>
                      )}
                      renderThumb={({index, props}) => {
                        return (
                          <div {...props} className='thumb' style={{...props.style}}>
                            <div className='counter'>
                              <p className='m-0 text-white'>
                                {moment.unix(value[index]).format('D MMM YYYY')}
                              </p>
                            </div>
                          </div>
                        )
                      }}
                     />
                  </div>
                  <ResponsiveContainer width='100%' minWidth={100} height={40} className='chart-area'>
                    <LineChart data={rangeValues}>
                      <XAxis
                        spacing={10}
                        strokeMiterlimit={10}
                        dataKey={i => moment.unix(i).format('MMM YY')}
                        allowDuplicatedCategory={false}
                        interval='preserveStartEnd'
                        stroke='#92929f'
                        tick={{fill: '#92929f'}}
                        tickSize={12}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
                <div className='bottomContainer px-9 mb-7 d-flex justify-content-end flex-wrap align-items-center'>
                  <div className='config form-check form-switch me-2 form-switch-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      checked={checked}
                      type='checkbox'
                      name='config'
                      onChange={handleChangeChecked}
                    />
                     <span className='text-muted fw-500 form-check-label'>Smart Validation</span>
                  </div>
                  <div className='me-4'>
                    <Hint description='If enabled the validationcore automatically chooses the validation with the most errors of that day, since it has the highest density of information'/>
                  </div>
                  {(refetchLoading || (validationTimeLoading && !checked)) && <span className="spinner-border align-middle mr-1"/> }
                  <div className='calendarContainer position-relative'>
                    <div className='calendarButton cursor-pointer btn btn-bg-gray-300 btn-active-primary button-active mr-1' onClick={() => setShowCalendar(true)}>
                      <KTSVG
                        path={'/media/icons/duotune/general/gen014.svg'}
                        className={cn('svg-icon-2 svg-icon-white m-0')}
                      />
                    </div>
                    {showCalendar &&
                    <div className='calendarWrapper' ref={calendarRef}>
                      <DateCalendar
                        onChange={handleDateValueChange}
                        defaultDate={moment.unix(value[0]).toDate()}
                        config={{
                          minDate: moment.unix(config[0]).toDate(),
                          maxDate: moment.unix(config[1]).toDate()
                        }}
                      />
                    </div>
                    }
                  </div>
                  <div className='validationButton'>
                    {checked &&
                      <Button withoutAnimation={!checked} fullWidth title={isLatestValidation ?  'Latest validation' : `Show ${moment.unix(value[0]).format('DD MMM. YYYY')}`} onClick={handleSubmit} disabled={(config[1] === currentValue && config[1] === value[0]) && checked}/>
                    }
                    {!checked &&
                      <div className={cn('d-flex justify-content-end align-items-center', {'no-pointer-events': validationTimeLoading})}>
                        <select
                          className='form-select form-select-solid btn-label w-100 cursor-pointer'
                          aria-label='Select time'
                          value={selectedTime}
                          onChange={({target}) => handleChangeTime(target.value)}
                        >
                          <option value="" disabled>Select time</option>
                          {validationTime.map(i => (
                            <option key={i.id} value={i.validation_date}>
                              {moment(i.validation_date).format('HH:mm')}
                            </option>
                          ))}
                        </select>
                      </div>
                    }
                  </div>
                </div>
              </>
          }
        </div>
      </div>
    </div>
  )
}

export default TimelineSlider

function getValidationIcon (validationLevel: ValidationLevel) {
  switch (validationLevel) {
    case ValidationLevel.SUCCESSALL:
    case ValidationLevel.SUCCESS:
      return <KTSVG path='/media/icons/duotune/general/gen043.svg' className='svg-icon-1 svg-icon-success'/>
    case ValidationLevel.INFO:
      return <KTSVG path='/media/icons/duotune/general/gen045.svg' className='svg-icon-1 svg-icon-info'/>
    case ValidationLevel.WARN:
      return <KTSVG path='/media/icons/duotune/general/gen044.svg' className='svg-icon-1 svg-icon-warning'/>
    default:
      return <KTSVG path='/media/icons/duotune/general/gen040.svg' className='svg-icon-1 svg-icon-danger'/>
  }
}
