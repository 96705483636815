import React from 'react'
import {AsideMenuItem} from './AsideMenuItem'

const menuItems = [
  {title: 'Chain'},
  {isAside: true, title: 'Chain overview', path: '/overview', icon: '/media/icons/duotune/layouts/lay001.svg'},
  {title: 'Guilds'},
  {isAside: true, title: 'Guild Validations', path: '/validations', icon: '/media/icons/duotune/general/gen004.svg'},
  {isAside: true, title: 'Guild Statistics', path: '/stats/guild', icon: '/media/icons/duotune/graphs/gra003.svg'},
  {title: 'Reports'},
  {
    isAside: true,
    title: 'Seed endpoints',
    path: '/reports/nodes/seed',
    icon: '/media/icons/blacklusion-duotone/seed.svg',
  },
  {isAside: true, title: 'Api endpoints', path: '/reports/nodes/api', icon: '/media/icons/blacklusion-duotone/api.svg'},
  {
    isAside: true,
    title: 'Wallet endpoints',
    path: '/reports/nodes/wallet',
    icon: '/media/icons/blacklusion-duotone/wallet.svg',
  },
  {
    isAside: true,
    title: 'History endpoints',
    path: '/reports/nodes/history',
    icon: '/media/icons/blacklusion-duotone/history.svg',
  },
  {
    isAside: true,
    title: 'Hyperion endpoints',
    path: '/reports/nodes/hyperion',
    icon: '/media/icons/blacklusion-duotone/hyperion.svg',
  },
  {
    isAside: true,
    title: 'Atomic endpoints',
    path: '/reports/nodes/atomic',
    icon: '/media/icons/blacklusion-duotone/atomic.svg',
  },
  {title: 'Services'},
  {
    isAside: true,
    title: 'Node Finder',
    path: '/services/node-finder',
    icon: '/media/icons/duotune/maps/map006.svg',
    badge: 'hot',
  },
  {
    isAside: true,
    title: 'Node Tester',
    path: '/services/node-tester',
    icon: '/media/icons/duotune/medicine/med001.svg'
  },
  {
    isAside: true,
    title: 'Network Info',
    path: '/services/network-info',
    icon: '/media/icons/duotune/coding/cod005.svg'
  },
  {
    isAside: true,
    title: 'Data Api',
    path: '/services/data-api',
    icon: '/media/icons/duotune/general/gen002.svg'
  }
]

export function AsideMenuMain() {
  return (
    <>
      {menuItems.map((i) =>
        !i.isAside ? (
          <div className='menu-item' key={i.title}>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{i.title}</span>
            </div>
          </div>
        ) : (
          <AsideMenuItem key={i.title} to={i.path} icon={i.icon} title={i.title} fontIcon='bi-app-indicator' badge={i.badge} />
        )
      )}
    </>
  )
}
