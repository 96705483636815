import cn from 'classnames'
import React, {FC, useEffect, useState} from 'react'
import {Range, getTrackBackground} from 'react-range'
import './style.scss'

interface Props {
  values: number[]
  step: number
  minValue: number
  maxValue: number
  onChange: (value: number[]) => void
  secondary?: boolean
  unit?: string
  largeThumb?: boolean
}

const RangeSlider: FC<Props> = ({values, step, minValue, maxValue, onChange, secondary, unit, largeThumb}) => {
  const trackColors = secondary ? ['#187de4', '#92929f'] : ['#92929f', '#187de4', '#92929f']
  const [range, setRange] = useState(values)
  const [rangeValues, setRangeValues] = useState<number[]>([values[0], maxValue])

  useEffect(() => {
    setRange(values)
    setRangeValues([values[0], maxValue])
  }, [values])

  const handleChange = (v: number[]) => {
    onChange(v)
    setRange(v)
    setRangeValues([v[0], maxValue])
  }

  const handleChangeValue = (type: number, target: EventTarget & HTMLInputElement) => {
    const newValue = Number(target.value.replace(/[^0-9\d]/g, ''))
    if (secondary) {
      if (type === 1) {
        if (newValue >= minValue && newValue <= maxValue) {
          setRange([newValue])
          setRangeValues([newValue, maxValue])
          onChange([newValue])
        }
      }
      return
    }
    type === 1
      ? newValue <= range[1] &&
        setRange((current) => {
          const newRange = [Number(newValue), current[1]]
          onChange(newRange)
          return newRange
        })
      : newValue <= maxValue &&
        newValue >= range[0] &&
        setRange((current) => {
          const newRange = [current[0], Number(newValue)]
          onChange(newRange)
          return newRange
        })
  }

  return (
    <div className='rangeSlider d-flex justify-content-center flex-wrap'>
      <div className={cn('leftValue d-flex mr-1 w-30px', {'w-40px': secondary,  'w-50px': largeThumb})}>
        <input value={secondary ? rangeValues[0] : range[0]} onChange={({target}) => handleChangeValue(1, target)} />
      </div>
      <Range
        values={range}
        step={step}
        min={minValue}
        max={maxValue}
        onChange={handleChange}
        renderTrack={({props, children}) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className='trackWrapper'
            style={{...props.style}}
          >
            <div
              ref={props.ref}
              className='track'
              style={{
                background: getTrackBackground({
                  values: range,
                  colors: trackColors,
                  min: minValue,
                  max: maxValue,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({index, props}) => (
          <div {...props} className='thumb' style={{...props.style}}>
            <div className='counter'>
              <p className='m-0 text-white'>
                {range[index]} {unit}
              </p>
            </div>
          </div>
        )}
      />
      <div className={cn('rightValue d-flex w-30px ml-1', {'w-40px': secondary, 'w-50px': largeThumb,'no-pointer-events': secondary})}>
        <input value={secondary ? rangeValues[1] : range[1]} onChange={({target}) => handleChangeValue(2, target)} />
      </div>
    </div>
  )
}

export default RangeSlider
