import cn from 'classnames'
import React, {useEffect, useState} from 'react'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Col, Row} from 'react-bootstrap-v5'
import {Helmet} from 'react-helmet'
import Skeleton from 'react-loading-skeleton'
import {useMediaQuery} from 'react-responsive'
import {useHistory} from 'react-router-dom'
import {usePageData} from '../../../core'
import {ChainItem, getChainItem} from 'config/chains'

export type PageTitleProps = {
  showNavigation: boolean
}

function PageTitle(props: PageTitleProps) {
  const {title, pageTitle, subtext, icon, loading, link} = usePageData()
  const history = useHistory()
  const [imageStatus, setImageStatus] = useState('loading')

  useEffect(() => {
    setImageStatus('loading')
  }, [icon])

  const isSmallScreen = useMediaQuery({query: '(max-width: 576px)'})

  const chainName = getChainItem(ChainItem.name)

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {!props.showNavigation ? (
        <Col>
          <div className='d-flex align-items-center justify-content-between'>
            {loading ? (
              <div className='no-desktop'>
                <Skeleton circle={true} height={25} width={25} className='mr-1' />
                <Skeleton height={25} width={170} />
              </div>
            ) : (
              <>
                <div className='d-flex align-items-center'>
                  {icon && (imageStatus === 'loading' || imageStatus === 'loaded') ?
                      <>
                        <img src={icon} onLoad={() => setImageStatus('loaded')} onError={() => setImageStatus('failed to load')} className={cn('mr-1 no-desktop', {'d-none': imageStatus === 'loading'})} alt='Logo' width='25' height='25' id='header-icon'/>
                        { imageStatus === 'loading' &&
                        <div className='h-25px w-25px mr-1 fallbackLogo no-desktop'/>
                        }
                      </>
                    : icon &&
                      <div className='h-25px w-25px mr-1 fallbackLogo no-desktop'/>
                  }
                  <h1 className='m-0 no-desktop'>{title}</h1>
                </div>
                <p className='m-0 text-gray-700'>{subtext}</p>
              </>
            )}
          </div>
        </Col>
      ) : (
        <Row className='align-items-center'>
          <Col className={cn('col-auto', {'cursor-pointer': link})} onClick={() => link && history.push(link)}>
            <div className='d-flex flex-column align-items-center'>
              <img
                src={'/media/icons/chains/' + getChainItem(ChainItem.logo)}
                alt='Header Icon'
                width={isSmallScreen ? '30' : '35'}
                height={isSmallScreen ? '30' : '35'}
                id='header-icon'
              />
              {isSmallScreen && <p className='mb-0 no-desktop fs-9 text-muted'>{chainName?.split(' ').slice(1)}</p>}
            </div>
          </Col>
          <Col className={cn('col-auto', {'cursor-pointer': link})} onClick={() => link && history.push(link)}>
            <h1 className='mb-0 no-mobile'>{chainName}</h1>
            {!isSmallScreen && <h3 className='mb-0 no-desktop'>{chainName?.split(' ').slice(1)}</h3>}
          </Col>
          <Col className='col-auto no-mobile'>
            <FontAwesomeIcon icon={faChevronRight} />
          </Col>
          <Col className='no-mobile'>
            <div className='d-flex align-items-center'>
              {loading ? (
                <Skeleton circle={true} height={35} width={35} className='mr-1' />
              ) : (
                icon && (imageStatus === 'loading' || imageStatus === 'loaded') ?
                  <>
                    <img src={icon} onLoad={() => setImageStatus('loaded')} onError={() => setImageStatus('failed to load')} className={cn('mr-1', {'d-none': imageStatus === 'loading'})} alt='Logo' width='35' height='35' id='header-icon'/>
                    {imageStatus === 'loading' &&
                      <div className='h-35px w-35px mr-1 fallbackLogo'/>
                    }
                  </>
                : icon &&
                  <div className='h-35px w-35px mr-1 fallbackLogo'/>
              )}
              {loading ? <Skeleton height={35} width={300} /> : <h1 className='mb-0'>{title}</h1>}
            </div>
          </Col>
        </Row>
      )}
    </>
  )
}

export default PageTitle
