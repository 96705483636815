import {gql} from '@apollo/client'

export const GET_ENDPOINTS = gql`
  query ($nodeType: NodeType!, $getOnlyWorking: Boolean, $timeOffsetInMin: Int, $queryMethod: QueryMethod) {
    getEndpoints(nodeType: $nodeType, getOnlyWorking: $getOnlyWorking, timeOffsetInMin: $timeOffsetInMin, queryMethod: $queryMethod) {
      endpoint_url
      guild
      is_ssl
      server_version
      all_checks_ok
      partial_checks_ok
      location_latitude
      location_longitude
    }
  }
`
