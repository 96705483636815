import {useQuery} from '@apollo/client'
import {KTSVG} from '_metronic/helpers'
import {PageData} from '_metronic/layout/core'
import cn from 'classnames'
import {Toolbar} from 'components'
import {ChainItem, getChainItem} from 'config/chains'
import {GET_GUILDS} from 'queries/getGuilds'
import React, {useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import {Overview} from './components'

import './style.scss'

const tabTitles = [
  'Info'
]

const NetworkInfo: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(tabTitles[0])

  const {data, loading, error} = useQuery<QueryGuildsRes>(GET_GUILDS)

  return (
    <>
      <PageData pageTitle='Network Info'>Network Info</PageData>
      <Toolbar />
      <div className='networkInfo'>
        <div className='card px-8 py-8 pb-0 mb-9'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-5'>
            <div className='me-7 mb-4'>
              <div className='avatar position-relative'>
                <img className="contained" src={'/media/icons/chains/' + getChainItem(ChainItem.logo)} alt='Metornic' />
              </div>
            </div>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <p className='text-gray-800 text-hover-primary mb-0 fs-2 fw-bolder me-1'>
                      {getChainItem(ChainItem.name)}
                    </p>
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      target="_blank"
                      href='https://wax.io'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen018.svg'
                        className='svg-icon-4 me-1'
                      />
                      https://wax.io
                    </a>
                  </div>
                </div>
                <div className='d-flex my-4'>
                  <a href={getChainItem(ChainItem.blocks)} target="_blank" className='btn btn-sm btn-primary button-active'>
                    bloks.io
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr095.svg'
                      className='svg-icon-4 me-0 ms-2'
                    />
                  </a>
                </div>
              </div>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='d-flex flex-column justify-content-center border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center justify-content-center mb-1 text-center'>
                        <h2 className="mb-0 w-100">{loading ? <Skeleton width='50%'/> : error ? '-' : data?.getGuilds.length}</h2>
                      </div>
                      <p className='fw-bold fs-6 text-gray-400 mb-0'>Tracked guilds</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex overflow-auto'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              {tabTitles.map(i => (
                <li className='nav-item cursor-pointer' key={i} onClick={() => setCurrentTab(i)}>
                  <p
                    className={cn('nav-link text-active-primary me-6', {'active': currentTab === i})}
                  >
                    {i}
                  </p>
                </li>
              ))
              }
            </ul>
          </div>
        </div>
        <div>
          <Overview/>
        </div>
      </div>
    </>
  )
}

export default NetworkInfo
