import {KTSVG} from '_metronic/helpers'
import React, {FC} from 'react'

import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'
import cn from 'classnames'

interface Props {
  description: string
  className?: string | undefined
}

const Hint: FC<Props> = ({description, className}) => {

  return (
    <div>
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement='top'
        overlay={(props) => <Tooltip id='tlp' {...props}><p className='mb-0'>{description}</p></Tooltip>}
      >
        <div className={cn('icon cursor-pointer button-active btn-active-primary', className)}>
          <KTSVG path='/media/icons/duotune/general/gen046.svg' className='svg-icon-1 svg-icon-gray-500' />
        </div>
      </OverlayTrigger>
    </div>
  )
}

export default Hint
