import React, {useEffect} from 'react'

/**
 * Tracks clicks from outside the passed element, and then triggers a callback
 * @param {React.RefObject<any>} ref
 * @param {callback} handler
 */
export default function useOnClickOutside(
  ref: React.RefObject<any>,
  handler: (event: MouseEvent | TouchEvent) => void
): void {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
