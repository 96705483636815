import {gql} from '@apollo/client'

export const VALIDATION_DATE_RANGE = gql`
  query ($guild: String!) {
    getValidationDateRange(guild: $guild) {
      min
      max
    }
  }
`
