import cn from 'classnames'
import {ValidationLevel} from 'consts'
import React, {FC} from 'react'
import {Table} from 'react-bootstrap-v5'
import {getReasonPhrase} from 'http-status-codes'

interface Props {
  validationObject: ValidationObjectUnion
}

const RequestOverview: FC<Props> = ({validationObject}) => {
  return (
    <div className='mt-5'>
      <Table size='sm' borderless={true} hover={false} className='mb-1'>
        <thead>
          <tr>
            <th className='text-gray-700'>Request</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width='15%'>
              <span className='badge badge-primary'>{'method' in validationObject && validationObject.method}</span>
            </td>
            <td className='text-break'>{'url' in validationObject && validationObject.url}</td>
          </tr>
          {'payload' in validationObject && validationObject.payload && (
            <tr>
              <td>
                <span className='badge badge-secondary'>Payload</span>
              </td>
              <td className='text-break'>{validationObject.payload}</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Table size='sm' borderless={true} hover={false}>
        <thead>
          <tr>
            <th className='text-gray-700'>Response</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width='15%'>
              {'http_code' in validationObject && validationObject.http_code ? (
                <span
                  className={cn(
                    'badge',
                    validationObject.status >= ValidationLevel.SUCCESS ? 'badge-success' : 'badge-danger'
                  )}
                >
                  {validationObject.http_code}
                </span>
              ) : (
                <span className='badge badge-danger'>Error</span>
              )}
            </td>
            <td>
              {'http_code' in validationObject &&
                (validationObject.http_code
                  ? getReasonPhrase(validationObject.http_code)
                  : validationObject.error_message)}
            </td>
          </tr>
          {'request_ms' in validationObject && validationObject.request_ms && (
            <tr>
              <td>
                <span className='badge badge-secondary'>Duration</span>
              </td>
              <td>{validationObject.request_ms + 'ms'}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default RequestOverview
