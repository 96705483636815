import moment from 'moment'
import React, {FC, useState} from 'react'
import {Calendar} from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './style.scss'

interface Props {
  onChange: (date: number) => void
  defaultDate: Date
  config: {
    minDate: Date
    maxDate: Date
  }
}

const DateCalendar: FC<Props> = ({onChange, defaultDate, config}) => {
  const [currentDate, setCurrentDate] = useState<Date>(defaultDate)

  const handleChange = (i: Date) => {
    setCurrentDate(i)
    onChange(moment(i).utc().unix())
  }

  return (
    <div className='dateCalendar'>
      <Calendar
        minDate={config.minDate}
        maxDate={config.maxDate}
        date={currentDate}
        onChange={handleChange}
      />
    </div>
  )
}

export default DateCalendar
