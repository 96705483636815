import React, {FC, useState} from 'react'
import cn from 'classnames'
import {Badge, Col, Row} from 'react-bootstrap-v5'
import {Link, useLocation} from 'react-router-dom'
import {ContentType} from 'consts'
import {ReactComponent as Organization} from 'assets/icons/organization.svg'
import {ReactComponent as Seed} from 'assets/icons/seed.svg'
import {ReactComponent as Api} from 'assets/icons/api.svg'
import {ReactComponent as Wallet} from 'assets/icons/wallet.svg'
import {ReactComponent as History} from 'assets/icons/history.svg'
import {ReactComponent as Hyperion} from 'assets/icons/hyperion.svg'
import {ReactComponent as Atomic} from 'assets/icons/atomic.svg'
import './style.scss'


type Props = {
  guildObject: Guild
  contentType: ContentType
}

const GuildRow: FC<Props> = ({guildObject, contentType}) => {
  const [imageStatus, setImageStatus] = useState('loading')
  const isValidation = contentType === ContentType.Validation
  const {search} = useLocation()

  if (!guildObject) return null

  const countryFlag = guildObject.location_alpha && guildObject.location_alpha.toLowerCase()
  return (
    <div className='guildRow card mt-4 card-hover'>
      <Link to={isValidation ? '/validations/' + guildObject.name : '/stats/guild/' + guildObject.name + search}>
        <div className='py-7 px-6'>
          <Row className='align-items-center'>
            <Col className='avatar col-lg-auto text-center'>
              {imageStatus === 'loading' || imageStatus === 'loaded' ?
                  <>
                    <img src={guildObject.url_logo_256} onLoad={() => setImageStatus('loaded')} onError={() => setImageStatus('failed to load')} width='35' height='35' alt='Guild logo' className={cn({'d-none': imageStatus === 'loading'})}/>
                    { imageStatus === 'loading' &&
                      <div className='h-35px w-35px fallbackLogo'/>
                    }
                  </>
                :
                  <div className='h-35px w-35px fallbackLogo'/>
              }
            </Col>
            <Col className='guildInformation col-lg-3'>
              <h2>{guildObject.name}</h2>
              <div className='align-items-center d-flex'>
                {guildObject.rank && (
                  <div className='mr-1'>
                    <Badge bg='secondary' text='white'>
                      {'#' + guildObject.rank}
                    </Badge>
                  </div>
                )}
                {guildObject.rank && guildObject.rank <= 21 && (
                  <div className='mr-1'>
                    <Badge bg='success'>Top 21</Badge>
                  </div>
                )}
                {countryFlag && (
                  <div>
                    <Badge
                      style={{
                        backgroundImage: `url(/media/flags/${countryFlag}.svg)`,
                        backgroundColor: 'none',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        color: 'transparent',
                      }}
                    >
                      XX
                    </Badge>
                  </div>
                )}
              </div>
            </Col>
            <Col className='space col-lg-auto' />
            {isValidation && (
              <Col className='element d-flex flex-column align-items-center'>
                <Organization
                  className={cn(
                    'mb-2',
                    guildObject.validation_levels.organization == "SUCCESS" ? 'validationIconTrue' : 'validationIconFalse'
                  )}
                />
                <p className={cn('m-0 text-white', {'text-gray-700': !(guildObject.validation_levels.organization == "SUCCESS")})}>
                  Organization
                </p>
              </Col>
            )}
            <Col className='element d-flex flex-column align-items-center'>
              {isValidation ? (
                <Seed
                  className={cn(
                    'mb-2',
                    guildObject.validation_levels.node_seed == "SUCCESS"
                      ? 'validationIconTrue'
                      : 'validationIconFalse'
                  )}
                />
              ) : (
                <h2 className={cn({'text-gray-700': !(guildObject.stats_availability.node_seed >= 1)})}>
                  {formatNumber(guildObject.stats_availability.node_seed)}
                </h2>
              )}
              <p
                className={cn('m-0 text-white', {
                  'text-gray-700': isValidation
                    ? !(guildObject.validation_levels.node_seed== "SUCCESS")
                    : !(guildObject.stats_availability.node_seed >= 1),
                })}
              >
                Seed
              </p>
            </Col>
            <Col className='element d-flex flex-column align-items-center'>
              {isValidation ? (
                <Api
                  className={cn(
                    'mb-2',
                    guildObject.validation_levels.node_api == "SUCCESS"
                      ? 'validationIconTrue'
                      : 'validationIconFalse'
                  )}
                />
              ) : (
                <h2 className={cn({'text-gray-700': !guildObject.stats_availability.node_api})}>
                  {formatNumber(guildObject.stats_availability.node_api)}
                </h2>
              )}
              <p
                className={cn('m-0 text-white', {
                  'text-gray-700': isValidation
                    ? !(guildObject.validation_levels.node_api == "SUCCESS")
                    : !(guildObject.stats_availability.node_api >= 1),
                })}
              >
                API
              </p>
            </Col>
            <Col className='element d-flex flex-column align-items-center'>
              {isValidation ? (
                <Wallet
                  className={cn(
                    'mb-2',
                    guildObject.validation_levels.node_wallet == "SUCCESS"
                      ? 'validationIconTrue'
                      : 'validationIconFalse'
                  )}
                />
              ) : (
                <h2 className={cn({'text-gray-700': !(guildObject.stats_availability.node_wallet >= 1)})}>
                  {formatNumber(guildObject.stats_availability.node_wallet)}
                </h2>
              )}
              <p
                className={cn('m-0 text-white', {
                  'text-gray-700': isValidation
                    ? !(guildObject.validation_levels.node_wallet == "SUCCESS")
                    : !(guildObject.stats_availability.node_wallet >= 1),
                })}
              >
                Wallet
              </p>
            </Col>
            <Col className='element d-flex flex-column align-items-center'>
              {isValidation ? (
                <History
                  className={cn(
                    'mb-2',
                    guildObject.validation_levels.node_history == "SUCCESS"
                      ? 'validationIconTrue'
                      : 'validationIconFalse'
                  )}
                />
              ) : (
                <h2 className={cn({'text-gray-700': !(guildObject.stats_availability.node_history >= 1)})}>
                  {formatNumber(guildObject.stats_availability.node_history)}
                </h2>
              )}
              <p
                className={cn('m-0 text-white', {
                  'text-gray-700': isValidation
                    ? !(guildObject.validation_levels.node_history == "SUCCESS")
                    : !(guildObject.stats_availability.node_history >= 1),
                })}
              >
                History
              </p>
            </Col>
            <Col className='element d-flex flex-column align-items-center'>
              {isValidation ? (
                <Hyperion
                  className={cn(
                    'mb-2',
                    guildObject.validation_levels.node_hyperion == "SUCCESS" || guildObject.validation_levels.node_hyperion_partial == "SUCCESS"
                      ? 'validationIconTrue'
                      : 'validationIconFalse'
                  )}
                />
              ) : (
                <h2 className={cn({'text-gray-700': !(guildObject.stats_availability.node_hyperion >= 1 || guildObject.stats_availability.node_hyperion_partial >= 1)})}>
                  {formatNumber(guildObject.stats_availability.node_hyperion >= guildObject.stats_availability.node_hyperion_partial ? guildObject.stats_availability.node_hyperion : guildObject.stats_availability.node_hyperion_partial)}
                </h2>
              )}
              <p
                className={cn('m-0 text-white', {
                  'text-gray-700': isValidation
                    ? !(guildObject.validation_levels.node_hyperion == "SUCCESS" || guildObject.validation_levels.node_hyperion_partial == "SUCCESS")
                    : !(guildObject.stats_availability.node_hyperion >= 1 || guildObject.stats_availability.node_hyperion_partial >= 1),
                })}
              >
                Hyperion {(guildObject.validation_levels.node_hyperion == "SUCCESS" || guildObject.stats_availability.node_hyperion >= 1) && "(full)" }
              </p>
            </Col>
            <Col className='element d-flex flex-column align-items-center'>
              {isValidation ? (
                <Atomic
                  className={cn(
                    'mb-2',
                    guildObject.validation_levels.node_atomic == "SUCCESS"
                      ? 'validationIconTrue'
                      : 'validationIconFalse'
                  )}
                />
              ) : (
                <h2 className={cn({'text-gray-700': !(guildObject.stats_availability.node_atomic >= 1)})}>
                  {formatNumber(guildObject.stats_availability.node_atomic)}
                </h2>
              )}
              <p
                className={cn('m-0 text-white', {
                  'text-gray-700': isValidation
                    ? !(guildObject.validation_levels.node_atomic == "SUCCESS")
                    : !(guildObject.stats_availability.node_atomic >= 1),
                })}
              >
                Atomic
              </p>
            </Col>
          </Row>
        </div>
      </Link>
    </div>
  )
}

export default GuildRow

/**
 * Returns number with % sign and converts NaN to --%
 * @param {number} number
 * @return {string}
 */
function formatNumber(number: number): string {
  if (!number) return '--%'
  else return number + '%'
}
