import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '_metronic/layout/core'
import {Routes} from './routing/Routes'
import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client'
import {SkeletonTheme} from 'react-loading-skeleton'
import {ChainItem, getChainItem} from 'config/chains'

const client = new ApolloClient({
  uri: getChainItem(ChainItem.api),
  cache: new InMemoryCache(),
})

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ApolloProvider client={client}>
        <SkeletonTheme color='#1e1e2d' highlightColor='#31314a'>
          <BrowserRouter basename={basename}>
            <I18nProvider>
              <LayoutProvider>
                <Routes />
              </LayoutProvider>
            </I18nProvider>
          </BrowserRouter>
        </SkeletonTheme>
      </ApolloProvider>
    </Suspense>
  )
}

export {App}
