import {useLazyQuery} from '@apollo/client'
import distance from '@turf/distance'
import cn from 'classnames'
import {getFilterParams} from 'helpers/getFilterParams'
import useOnClickOutside from 'hooks/useOnClickOutside'
import _ from 'lodash'
import React, {FC, useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {GET_ENDPOINTS} from 'queries/getEndpoints'
import {Toolbar, Map, SyntaxHighlighter, Button, FilterModal} from 'components'
import {PageData} from '_metronic/layout/core'
import {InfoBoard} from './components'
import {KTSVG} from '_metronic/helpers'
import {getNodeType} from 'helpers/getNodeType'
import {defaultEndpointContent, NodeType} from 'consts'
import './style.scss'

const defaultRangeValue = [10000]

const highlightStyles = {
  "hljs-comment": {
    "color": "#565674"
  },
  "code[class*=\"language-\"]": {
    "color": "#fff"
  }
}

const sliderConfig = {step: 1, minValue: 60, maxValue: 1440, unit: 'min'}

const endpointContent: FilterContent[] = [...defaultEndpointContent,
  {
    header: 'Expert Settings',
    label: 'Query Method',
    secondaryLabel: true,
    isSelect: true,
    elements: [
      {
        type: 'select',
        label: 'Atleast one Validation',
        value: 'atleastone',
      },
      {
        type: 'select',
        label: 'All Validations',
        value: 'all',
      },
    ],
    hint: 'If set to "All Validations", all validations within the TimeOffset need to be working; If set to "Atleast one" only a single Validation needs to work in order to score a working status',
    hidden: true
  },
  {
    label: 'Time Offset',
    secondaryLabel: true,
    elements: [
      {
        type: 'secondaryRangeSlider',
        value: 'timeoffset',
        sliderConfig
      }
    ],
    hint: 'The bigger the TimeOffset the more validations will be used to calculate the List. Together with the QueryMethod a bigger TimeOffset will make it more strict (all validations) or loose (atleast one) to score a working status',
    hidden: true
  }
]

const defaultRanges: RangeItem[] = [
  {name: 'timeoffset', values: [60]}
]

const defaultSelectedItems = ['working']

const defaultTimeOffsetInMin = 60

const NodeFinder: FC = () => {
  const [nodeType, setNodeType] = useState<string>(NodeType.NODE_SEED)
  const [markers, setMarkers] = useState<Marker[]>([])
  const [secondaryMarkers, setSecondaryMarkers] = useState<Marker[]>([])
  const [rangeValue, setRangeValue] = useState(defaultRangeValue)
  const [markerCoordinates, setMarkerCoordinates] = useState<number[]>([])
  const [endpointUrls, setEndpointUrls] = useState<string[]>([])
  const [defaultMarkers, setDefaultMarkers] = useState<Marker[]>([])
  const [userLocationCoordinates, setUserLocationCoordinates] = useState<number[]>([])
  const [showModal, setShowModal] = useState(false)
  const [content, setContent] = useState<FilterContent[]>(endpointContent)
  const [selectedItems, setSelectedItems] = useState<string[]>(defaultSelectedItems)
  const [selectedOption, setSelectedOption] = useState<FilterItem | undefined>(undefined)
  const [multiSelectedOptions, setMultiSelectedOptions] = useState<FilterItem[]>([])
  const [ranges, setRages] = useState(defaultRanges)
  const [queryMethod, setQueryMethod] = useState(nodeType === 'NODE_SEED' ? 'ATLEAST_ONE_SUCCESSFUL_VALIDATION' : 'ALL_SUCCESSFUL_VALIDATION')
  const [timeOffsetInMin, setTimeOffsetInMin] = useState(defaultTimeOffsetInMin)
  const [currentFilter, setCurrentFilter] = useState<FilterParams>({})
  const modalRef = useRef(null)
  const {search} = useLocation()

  const [getEndpoints, {data: endpointsData, error: endpointsError, loading: endpointsLoading, refetch, called}] = useLazyQuery<QueryEndpointsRes>(GET_ENDPOINTS, {
    notifyOnNetworkStatusChange: true
  })

  useOnClickOutside(modalRef, () => {setShowModal(false)})

  const getDefaultSelectedOption = () => {
    return endpointContent[endpointContent.length - 2].elements[nodeType === 'NODE_SEED' ? 0 : 1]
  }

  const getQueryMethod = (type: string) => type === 'NODE_SEED' ? 'ATLEAST_ONE_SUCCESSFUL_VALIDATION' : 'ALL_SUCCESSFUL_VALIDATION'

  useEffect(() => {
    const currentContent = nodeType === 'NODE_SEED' || (getNodeType(search) === 'NODE_SEED') ? endpointContent.map(i => ({...i, elements: i.elements.filter(el => el.value !== 'ssl')})) : endpointContent
    setContent(currentContent)
    const endpoints = endpointsData?.getEndpoints
    if (endpoints) {
      handleSort(currentFilter)
      const currentServerVersions: FilterItem[] = []
      endpoints.forEach(i => {
        if (i.server_version) {
          const server_version = i.server_version.split(', ')
          server_version.forEach(el => {
            currentServerVersions.push({ type: 'select', label: el, value: el })
          })
        }
      })
      if (currentServerVersions.length) {
        const serverVersion = [...currentContent]
        serverVersion.splice(1, 0, {
          label: 'Server version',
          isMultiSelect: true,
          elements: _.uniqBy(currentServerVersions, 'value')
        })
        setContent(serverVersion)
      }
    }
  }, [endpointsData])

  useEffect(() => {
    const currentNodeType = getNodeType(search)
    let defaultQueryMethod
    if (currentNodeType && currentNodeType !== nodeType) {
      setNodeType(currentNodeType)
      defaultQueryMethod = getQueryMethod(currentNodeType)
    } else {
      defaultQueryMethod = getQueryMethod(nodeType)
    }
    if (search.length && !endpointsLoading) {
      const items: FilterItem[] = []
      const options: FilterItem[] = []
      const multiOptions: FilterItem[] = []
      content.forEach(i => {
        if (i.isMultiSelect) {
          return multiOptions.push(...i.elements)
        }
        if (i.isSelect) {
          return options.push(...i.elements)
        }
        return items.push(...i.elements)
      })
      const itemVerification = !!items.length && new RegExp(items.map(i => i.value.toLowerCase()).join('=(t|f)|') + '=(t|f)')
      const optionVerification = !!options.length && new RegExp(`querymethod=${options.map(i => `(${i.value.toLowerCase()})`).join('|')}`)
      const multiOptionsVerification = !!multiOptions.length && new RegExp(multiOptions.map(i => i.value.toLowerCase()).join('|'))
      const rangeVerification = new RegExp('timeoffset=\\[\\d{2,4}\\]')
      const currentFilterParams = getFilterParams(search, 'multi', itemVerification, optionVerification, multiOptionsVerification, rangeVerification, [sliderConfig.minValue, sliderConfig.maxValue])
      if (currentFilterParams) {
        setCurrentFilter(currentFilterParams.params)
        let currentQueryMethod
        let timeOffset
        if (currentFilterParams.params.selectedOption) {
          if (currentFilterParams.params.selectedOption.value === 'all') {
            currentQueryMethod = 'ALL_SUCCESSFUL_VALIDATION'
          } else {
            currentQueryMethod = 'ATLEAST_ONE_SUCCESSFUL_VALIDATION'
          }
        } else {
          currentQueryMethod = defaultQueryMethod
        }
        if (currentFilterParams.params.ranges?.length) {
          timeOffset = currentFilterParams.params.ranges[0].values[0]
        } else {
          timeOffset = defaultTimeOffsetInMin
        }
        if (!called) {
          getEndpoints({variables: {nodeType: currentNodeType || nodeType, getOnlyWorking: false, timeOffsetInMin: timeOffset, queryMethod: currentQueryMethod}})
        } else {
          if (currentQueryMethod !== queryMethod || timeOffset !== timeOffsetInMin) {
            refetch({nodeType: currentNodeType || nodeType, getOnlyWorking: false, timeOffsetInMin: timeOffset, queryMethod: currentQueryMethod})
          } else {
            handleSort(currentFilterParams.params)
          }
        }
        setQueryMethod(currentQueryMethod)
        setTimeOffsetInMin(timeOffset)
      } else {
        if (!called) {
          getEndpoints({variables: {nodeType: currentNodeType || nodeType, getOnlyWorking: false, timeOffsetInMin, queryMethod}})
        } else {
          if (currentNodeType !== nodeType) {
            refetch({nodeType: currentNodeType || nodeType, getOnlyWorking: false, timeOffsetInMin: defaultTimeOffsetInMin, queryMethod: defaultQueryMethod})
          }
        }
      }
    } else {
      setCurrentFilter({})
      handleSort({})
      if (!called) {
        getEndpoints({variables: {nodeType: currentNodeType || nodeType, getOnlyWorking: false, timeOffsetInMin, queryMethod}})
      } else {
        if (!endpointsLoading && (defaultTimeOffsetInMin !== timeOffsetInMin || defaultQueryMethod !== queryMethod)) {
          refetch({nodeType: currentNodeType || nodeType, getOnlyWorking: false, timeOffsetInMin: defaultTimeOffsetInMin, queryMethod: defaultQueryMethod})
          setQueryMethod(defaultQueryMethod)
          setTimeOffsetInMin(defaultTimeOffsetInMin)
        }
      }
    }
  }, [search, content])

  const handleChangeNodeType = (v: string) => {
    setNodeType(v)
    setRangeValue(defaultRangeValue)
    setCurrentFilter({})
    const queryMethod = getQueryMethod(v)
    setQueryMethod(queryMethod)
    refetch({nodeType: v, getOnlyWorking: false, timeOffsetInMin: defaultTimeOffsetInMin, queryMethod: queryMethod})
  }

  const handleChangeRangeValue = (v: number[]) => {
    setRangeValue(v)
  }

  useEffect(() => {
    if (markerCoordinates.length) {
      const markers = _.sortBy(defaultMarkers, (marker) => {
        return distance(markerCoordinates, marker.coordinates, {units: 'kilometers'})
      })
      const filteredMarkers: Marker[] = []
      const filteredSecondaryMarkers: Marker[] = []
      const currentEndpointUrls: string[] = []
      markers.forEach((i) => {
        if (distance(markerCoordinates, i.coordinates, {units: 'kilometers'}) <= rangeValue[0]) {
          filteredMarkers.push(i)
          currentEndpointUrls.push(i.url)
        } else {
          filteredSecondaryMarkers.push(i)
        }
      })
      setMarkers(filteredMarkers)
      setSecondaryMarkers(filteredSecondaryMarkers)
      setEndpointUrls(currentEndpointUrls)
    }
  }, [markerCoordinates, rangeValue, defaultMarkers])

  const handleUserLocation = () => {
    setUserLocationCoordinates([])
    navigator.geolocation.getCurrentPosition((pos) => {
      setUserLocationCoordinates([pos.coords.longitude, pos.coords.latitude])
      !markerCoordinates.length && setMarkerCoordinates([pos.coords.longitude, pos.coords.latitude])
    })
  }

  const sortEndpoints = (data: Endpoint[]) => {
    const currentEndpointUrls: string[] = []
    const currentMarkers: Marker[] = []
    data.forEach((i) => {
      if (i.location_longitude && i.location_latitude) {
        currentEndpointUrls.push(i.endpoint_url)
        currentMarkers.push({
          url: i.endpoint_url,
          isWorking: i.all_checks_ok === 4,
          coordinates: [i.location_longitude, i.location_latitude],
        })
      }
    })
    setMarkers(currentMarkers)
    setEndpointUrls(currentEndpointUrls)
    setDefaultMarkers(currentMarkers)
  }

  const handleReset = () => {
    setSelectedItems(defaultSelectedItems)
    setSelectedOption(getDefaultSelectedOption())
    setMultiSelectedOptions([])
    setRages(defaultRanges)
    setCurrentFilter({})
    handleSort({})
  }

  const handleSort = (params: FilterParams) => {
    if (endpointsData?.getEndpoints) {
      let modifyParams
      let currentEndpoints = endpointsData.getEndpoints
      if (params.selectedItems?.length) {
        let items = params.selectedItems
        if (params.selectedItems.includes('ssl')) {
          currentEndpoints = currentEndpoints.filter(i => i.is_ssl)
        }
        if (params.selectedItems.includes('working')) {
          currentEndpoints = currentEndpoints.filter(i => i.all_checks_ok === 4)
        }
        if (params.selectedItems.includes('working=f')) {
          items = items.filter(i => i !== 'working=f')
        } else {
          currentEndpoints = currentEndpoints.filter(i => i.all_checks_ok === 4)
          !items.includes('working') && items.push(defaultSelectedItems[0])
        }
        setSelectedItems(items)
        modifyParams = {...params, selectedItems: items}
      } else {
        setSelectedItems(defaultSelectedItems)
        modifyParams = {...params, selectedItems: defaultSelectedItems}
        currentEndpoints = currentEndpoints.filter(i => i.all_checks_ok === 4)
      }
      if (params.multiSelectedOptions?.length) {
        const currentParams = params.multiSelectedOptions.map(i => i.value)
        currentEndpoints = currentEndpoints.filter(i => {
          let isMatched = false
          const currentServerVersion = i.server_version?.split(', ')
          currentServerVersion && currentServerVersion.forEach(i => {
            if (currentParams.includes(i)) {
              isMatched = true
            }
          })
          return isMatched
        })
        setMultiSelectedOptions(params.multiSelectedOptions)
      } else {
        setMultiSelectedOptions([])
      }
      if (params.ranges?.length) {
        setRages(params.ranges)
      } else {
        setRages(defaultRanges)
      }
      if (params.selectedOption) {
        setSelectedOption(params.selectedOption)
      } else {
        setSelectedOption(getDefaultSelectedOption())
      }
      setCurrentFilter(modifyParams)
      sortEndpoints(currentEndpoints)
    }
  }

  return (
    <>
      <PageData link='/overview' pageTitle='Node Finder'>Node Finder</PageData>
      <Toolbar
        rightChildren={
          <>
            <Button
              title='Filter'
              icon='/general/gen031.svg'
              loading={endpointsLoading}
              onClick={() => setShowModal(true)}
              disabled={!!endpointsError}
              pulse
            />
            <div className={cn('renderModal', {show: showModal})} ref={modalRef}>
              <FilterModal
                currentRanges={ranges}
                defaultRanges={defaultRanges}
                content={content}
                filterType='multi'
                showModal={showModal}
                currentSelectedItems={selectedItems}
                defaultSelectedItems={defaultSelectedItems}
                defaultSelectedOption={getDefaultSelectedOption()}
                currentSelectedOption={selectedOption}
                defaultMultiSelectedOptions={multiSelectedOptions}
                onReset={handleReset}
                onSubmit={() => ({})}
                hideModal={() => setShowModal(false)}
              />
            </div>
          </>
        }
      />
      <div className='nodeFinder col-xxl-12'>
        {!endpointsLoading && (
          <div className='userLocation cursor-pointer' onClick={handleUserLocation}>
            <KTSVG path={'/media/icons/duotune/maps/map007.svg'} className='svg-icon-primary svg-icon-2x' />
          </div>
        )}
        <div className='mb-5 mb-xxl-8 overflow-hidden'>
          <Map
            withoutHeader
            loading={endpointsLoading}
            markers={markers}
            radius={rangeValue[0]}
            onChangeMarkerCoordinates={(v) => setMarkerCoordinates(v)}
            markerCoordinates={markerCoordinates}
            nodeType={nodeType}
            userLocationCoordinates={userLocationCoordinates}
            secondaryMarkers={secondaryMarkers}
          />
          <div className='board w-350px'>
            <InfoBoard
              marker={!!markerCoordinates.length && !endpointsLoading}
              value={rangeValue}
              onChangeRangeValue={handleChangeRangeValue}
              nodeType={nodeType}
              nodeTypes={Object.values(NodeType)}
              onChangeNodeType={handleChangeNodeType}
            />
          </div>
        </div>
        <div className='mb-5 mb-xxl-8'>
          <SyntaxHighlighter
            header='Text list'
            title='Adjust the filter on the top right to adjust the list. Endpoints are sorted with ascending distance'
            loading={endpointsLoading}
            data={endpointUrls}
            fileName='data.txt'
            config={nodeType === 'NODE_SEED'}
            style={nodeType === 'NODE_SEED' ? highlightStyles : undefined}
          />
        </div>
      </div>
    </>
  )
}

export default NodeFinder
