import React, {FC} from 'react'
import './style.scss'

interface Props {
  title?: string
}

const NoDataAvailable: FC<Props> = ({title}) => {
  return (
    <div className='noDataAvailable justify-content-center align-items-center d-flex min-h-400px'>
      <h1>{title || 'No data available'}</h1>
    </div>
  )
}

export default NoDataAvailable
