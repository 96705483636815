import {getUrlParams} from 'helpers/getUrlParams'
import React, {FC, useEffect, useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import {useHistory, useLocation} from 'react-router-dom'
import {KTSVG} from '_metronic/helpers'
import cn from 'classnames'
import './style.scss'

interface Props {
  content: DateItem[]
  defaultDateValue: DateItem
  onChange: (dateValue: DateItem) => void
  isMobile?: boolean
  showCalendar: () => void
  loading?: boolean
}

const DateFilter: FC<Props> = ({content, defaultDateValue, onChange, isMobile, showCalendar, loading}) => {
  const [dateValue, setDateValue] = useState(defaultDateValue)
  const [width, setWidth] = useState(0)
  const [offset, setOffset] = useState(0)
  const history = useHistory()
  const {search} = useLocation()

  useEffect(() => {
    setDateValue(defaultDateValue)
  }, [defaultDateValue])

  const handleClick = (i: DateItem) => {
    const offsetLeft = document.getElementById(i.label)?.offsetLeft || 0
    setOffset(offsetLeft)
    const width = document.getElementById(i.label)?.offsetWidth || 0
    setWidth(width)
    setDateValue(i)
    onChange(i)

    const query = getUrlParams(search)
    let currentQuery = i.queryParams ? `daterange=${i.queryParams}` : ''
    if (query) {
      currentQuery = currentQuery ? query + '&' + currentQuery : query
    } else {
      currentQuery = currentQuery && '?' + currentQuery
    }
    history.replace({search: currentQuery})
  }

  useEffect(() => {
    const offsetLeft = document.getElementById(defaultDateValue.label)?.offsetLeft || 0
    setOffset(offsetLeft)
    const width = document.getElementById(defaultDateValue.label)?.offsetWidth || 0
    setWidth(width)
  }, [defaultDateValue, content, loading])

  return (
    <div className={cn('dateFilter d-flex align-items-center', {mobileDateFilter: isMobile})}>
      {loading && (
        <>
          <Skeleton height={32} width={200} className='darkLoading' />
          <Skeleton height={32} width={50} className='darkLoading buttonLoading' />
        </>
      )}
      <div className={cn('switches', {'d-none': loading})}>
        {content.map((i) => (
          <div id={i.label} key={i.label} className='switch mx-2 cursor-pointer' onClick={() => handleClick(i)}>
            <p className={cn('mb-0', i.label === dateValue.label ? 'text-white' : 'text-gray-700')}>{i.label}</p>
          </div>
        ))}
        <div className='activeItem' style={{width, left: offset}} />
      </div>
      <div className={cn('calendarButton position-relative', {'d-none': loading})}>
        <div
          onClick={showCalendar}
          className='d-flex align-items-center cursor-pointer btn btn-sm btn-bg-gray-300 btn-active-primary button-active'
        >
          <KTSVG
            path={'/media/icons/duotune/general/gen014.svg'}
            className={cn('svg-icon-3 svg-icon-white me-1', {'svg-icon-4 me-2': isMobile})}
          />
          {isMobile && <p className='mb-0 text-white'>Custom range</p>}
          {!isMobile && (
            <div className={cn('range d-flex align-items-center', {hideRange: dateValue.label !== 'calendar'})}>
              <p className='mb-0 text-gray-700'>{dateValue.parameter[1]}</p>
              <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-muted svg-icon' />
              <p className='mb-0 text-gray-700'>{dateValue.parameter[0]}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DateFilter
